import {Injectable} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {LocalStorageService} from "./local-storage.service";

const DRAFT_STORAGE = "draft";
const DRAFT_FORMAT_VERSION = '1.0';
const NEW_DRAFT_FLAG_STORAGE = "is-new-draft";
const DRAFT_SAVE_TIME_STORAGE = 'draft-save-time';

/**
 * Время жизни сохранённой заявки в секундах
 */
const DRAFT_TTL = 20 * 60;

@Injectable()
export class DraftStorageService {
  constructor(private localStorageService: LocalStorageService) {
  }

  saveDraft(draft: OrderDraft): void {
    let value = JSON.stringify({
      version: DRAFT_FORMAT_VERSION,
      draft
    });
    this.localStorageService.setItem(DRAFT_STORAGE, value);
    this.localStorageService.setItem(DRAFT_SAVE_TIME_STORAGE, new Date().toString());
  }

  restoreDraft(): OrderDraft|undefined {
    let saveTimeString = this.localStorageService.getItem(DRAFT_SAVE_TIME_STORAGE);
    if(saveTimeString) {
      let saveTime = new Date(saveTimeString);
      if(isNaN(saveTime.getDate()))
        return undefined;

      let timeDiff = new Date().getTime() - saveTime.getTime();
      if(timeDiff / 1000 > DRAFT_TTL)
        return undefined;
    }

    let value = this.localStorageService.getItem(DRAFT_STORAGE);
    if(value === null)
      return undefined;

    let data: any = JSON.parse(value);
    return data.version === DRAFT_FORMAT_VERSION ? data.draft as OrderDraft : undefined;
  }

  removeDraftFromStorage(): void {
    this.localStorageService.removeItem(DRAFT_STORAGE);
  }

  setNewDraftFlag(): void {
    this.localStorageService.setItem(NEW_DRAFT_FLAG_STORAGE, 'true');
  }

  resetNewDraftFlag(): void {
    this.localStorageService.removeItem(NEW_DRAFT_FLAG_STORAGE);
  }

  isNewDraftActive(): boolean {
    return this.localStorageService.getItem(NEW_DRAFT_FLAG_STORAGE) === 'true';
  }
}
