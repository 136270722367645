<div class="preview_background">
  <div class="preview_background_content">
    <div class="logo_block">
      <div class="bottom_header_content_mobil2"></div>
      <div class="logo_box"></div>
    </div>
    <div class="preview">
      <div class="left_preview">
        <div class="left_preview_title">Оставьте подбор перевозчика на нас</div>
        <div class="left_preview_mini_title">Экономьте на грузоперевозках до 30% с умной системой подбора ближайшего исполнителя</div>
        <div class="left_preview_btn_mobil" (click)="onCreateDraft()">Оформить заказ</div>
        <div class="left_preview_items">
          <div class="item_left_preview">🔔 Любой груз до 1,5 тонн</div>
          <div class="item_left_preview">↩️ 3040 активных экипажей</div>
          <div class="item_left_preview">☂️ 1 или 2 грузчика</div>
          <div class="item_left_preview">📊 100% ответственность</div>
        </div>
      </div>
      <div class="right_preview">
        <init-order-block></init-order-block>
      </div>
    </div>
    <div class="bottom_preview">
      <div class="bottom_preview_text">Совместно с партнером</div>
      <div class="bottom_preview_icon"></div>
    </div>
  </div>
</div>
<div class="advantages">
  <div class="advantages_content">
    <div class="item_advantages">
      <div class="item_advantages_icon advantages_icon1"></div>
      <div class="item_advantages_title">Без комиссии</div>
      <div class="item_advantages_text">Сервис работает с экипажами напрямую, вы получаете цены без скрытых накруток и переплат</div>
    </div>
    <div class="item_advantages">
      <div class="item_advantages_icon advantages_icon2"></div>
      <div class="item_advantages_title">Лучшие перевозчики</div>
      <div class="item_advantages_text">Мы проверяем каждого водителя, оцениваем состояние транспорта и опыт исполнителя</div>
    </div>
    <div class="item_advantages">
      <div class="item_advantages_icon advantages_icon3"></div>
      <div class="item_advantages_title">Заказ в 3 клика</div>
      <div class="item_advantages_text">Получите доступ к любым ближайшим экипажам всего за пару минут на любой платформе</div>
    </div>
  </div>
</div>
<div class="advantages_mobil">
  <div class="advantages_mobil_icon">₽</div>
  <div class="advantages_mobil_prices">от 890₽/час </div>
  <div class="advantages_mobil_text">Сервис работает с перевозчиками напрямую, вы получаете цены без скрытых накруток и переплат</div>
  <a class="advantages_mobil_href" href="https://api.mover24.ru/page/fares/client?tiers=mini2,standart2,extra2">Подробнее о тарифах</a>
</div>
<div class="question">
  <div class="question_content">
    <div class="question_title">Часто задаваемые вопросы</div>
    <div class="question_mini_title">Поможем подобрать надежного исполнителя для вашей грузоперевозки</div>

    <div class="question_box " [class.question_box_active]="isFaqSectionOpen(0)">
      <div class="head_question_box" (click)="onToggleFaqSection(0)">
        <div class="question_box_arrow"></div>
        <div class="question_box_text">Как понять в какую машину поместиться мой груз?</div>
      </div>
      <div class="text_question_box">
        <p>
        Для того, что бы точно понять, какой вам потребуется автомобиль, оцените размеры и вес своего груза.
        </p>

        <p>
          Для перевозки небольших и компактных грузов отлично подойдёт тариф <strong>«МИНИ»:</strong><br>
          - В него входят авто грузоподъемностью до 900 кг, Длинной от 1,5 до 2 м, Шириной от 1 до 1,4 м, Высотой от 1 до 1,4 м, Объемом от 3 до 5 кубов
        </p>
        <p>
          Для перевозки небольшого кол-ва предметов мебели и техники, небольших переездов подойдёт тариф <strong>«СТАНДАРТ»</strong>:<br>
          - В него входят авто грузоподъемностью до 1,5 тонн, Длинной от 2,5 до 3 м, Шириной от 1,5 до 2 м, Высотой от 1,8 до 2,2 м, Объемом от 9 до 12 кубов
        </p>
        <p>
          Для крупных квартирных переездов, длинных и высоких грузов вам подойдёт тариф <strong>«ЭКСТРА»</strong>:<br>
          - В него входят авто грузоподъемностью до 1,5 тонн, Длинной до 4 м, Шириной от 1,8 до 2,2 м, Высотой от 2 до 2,2 м, Объемом от 14 до 16 кубов
        </p>
        <p>
        Если вам нужна консультация нашего специалиста, вы всегда сможете позвонить на нашу горячую линию по номеру +74954143713 и мы с радостью вам поможем!
        </p>
      </div>
    </div>
    <div class="question_box " [class.question_box_active]="isFaqSectionOpen(1)">
      <div class="head_question_box" (click)="onToggleFaqSection(1)">
        <div class="question_box_arrow"></div>
        <div class="question_box_text">Как мне связаться с водителем?</div>
      </div>
      <div class="text_question_box">
        <p>
        После того, как мы примем ваш заказ, начнется подбор ближайшего к вам перевозчика. Как только один из перевозчиков примет ваш заказ, вам в смс сообщении прийдет номер телефона и номер авто данного перевозчика.
        </p>
        <p>
        После этого вы можете связаться с перевозчиком напрямую и дать все необходимые по вашему заказу комментарии.
        </p>
      </div>
    </div>
    <div class="question_box " [class.question_box_active]="isFaqSectionOpen(2)">
      <div class="head_question_box" (click)="onToggleFaqSection(2)">
        <div class="question_box_arrow"></div>
        <div class="question_box_text">Когда ко мне приедет экипаж?</div>
      </div>
      <div class="text_question_box">
        <p>
        Наш сервис всегда подбирает экипажи поблизости с вашей точкой загрузки. Среднее время подачи экипажа на ваш адрес занимает от 30 минут до 1 часа. В пиковые часы с высокой загруженностью дорог, время может быть увеличено до 1,5 часов.
        </p>
      </div>
    </div>
    <div class="question_box " [class.question_box_active]="isFaqSectionOpen(3)">
      <div class="head_question_box" (click)="onToggleFaqSection(3)">
        <div class="question_box_arrow"></div>
        <div class="question_box_text">Что входит в стоимость услуги?</div>
      </div>
      <div class="text_question_box">
        <p>
        Общая стоимость перевозки складывается из следующих параметров:
          <br>
          — <strong>время работы на заказе</strong> — рассчитывается по таксометру в приложении водителя согласно выбранному тарифу;<br>
          — <strong>пробег за пределами МКАД во время заказа</strong> — расчет за каждый пройденный километр;<br>
          — <strong>количество грузчиков</strong> — оплата за работу по таксометру (при движении между точками стоимость грузчиков снижается в 2 раза);<br>
          — <strong>подъем груза на этажи без лифта</strong>  — прибавляет к стоимости грузчика +100 рублей;<br>
        </p>
      </div>
    </div>
    <div class="question_box " [class.question_box_active]="isFaqSectionOpen(4)">
      <div class="head_question_box" (click)="onToggleFaqSection(4)">
        <div class="question_box_arrow"></div>
        <div class="question_box_text">В каких городах вы работаете?</div>
      </div>
      <div class="text_question_box">
        <p>
          Наш сервис работает только в Москве и Московской области.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <div class="footer_content">
    <div class="logo_footer_box">
      <div class="logo_footer"></div>
    </div>
    <div class="box_footer">
      <div class="top_box_footer">
        <div class="contacts_top_box_footer">
          <div class="text_top_box_footer">Телефоны службы поддержки</div>
          <div class="vector_top_box_footer"></div>
          <div class="btn_top_box_footer" (click)="onOpenChat()">Техподдержка</div>
        </div>
      </div>
      <div class="midlle_box_footer">
        <div class="phone_top_box_footer">+7 495 646 83 62</div>
<!--        <div class="phone_top_box_footer">+7 495 981 68 81</div>-->
      </div>
      <div class="bottom_box_footer">
        <div class="bottom_box_footer_text"><a [routerLink]="['/terms-of-use']">Пользовательское соглашение</a></div>
        <div class="bottom_box_footer_text"><a [routerLink]="['/privacy-policy']">Политика конфиденциальности</a></div>
        <div class="bottom_box_footer_text_long"><a [routerLink]="['/terms-and-definitions']">Термины и определения</a></div>
      </div>
    </div>
  </div>
</div>

<!--<div class="mobil_footer">-->
<!--  <div class="mobil_footer_mini_content">-->
<!--    <div class="mobil_top_mini_footer">-->
<!--      <div class="mobil_top_mini_footer_menu">Помощь</div>-->
<!--      <div class="mobil_top_mini_footer_menu">О компании</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Вакансии</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Безопасность</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Реклама на сайте</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Оферта</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Политика о данных пользователей</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Условия использования авито</div>-->
<!--    </div>-->
<!--    <div class="mobil_top_mini_footer_copir">© ООО «КЕХ еКоммерц» 2007-2021</div>-->
<!--    <div class="mobil_bottom_mini_footer">-->
<!--      <div class="item_mobil_bottom_mini_footer">Мобильное приложение</div>-->
<!--      <div class="item_mobil_bottom_mini_footer">Полная версия</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
