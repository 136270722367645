export const SEARCH_STATUSES_GROUPS: any = {
  search: {
    name: 'Идёт поиск',
    statuses: [ 'init', 'search', 'search_via_links' ]
  },
  stopped: {
    name: 'Остановлен',
    statuses: [ 'stopping', 'stopped' ]
  },
  paused: {
    name: 'Приостановлен',
    statuses: [ 'pausing', 'paused' ]
  },
  completed: {
    name: 'Подтверждён водителем',
    statuses: [ 'completed' ]
  },
  accepted: {
    name: 'Подтверждён клиентом',
    statuses: [ 'accepted' ]
  },
  declined: {
    name: 'Отклонён',
    statuses: [ 'declined' ]
  },
  notFound: {
    name: 'Водитель не найден',
    statuses: [ 'not_found' ]
  },
  error: {
    name: 'Ошибка поиска',
    statuses: [ 'error' ]
  }
};
