export class Point {
  lat: number | undefined;
  private _lon: number | undefined;
  private _lng: number | undefined;


  set lon(value: number | undefined) {
    this._lon = this._lng = value;
  }

  set lng(value: number | undefined) {
    this.lon = value;
  }

  get lng(): number | undefined {
    return this._lng;
  }

  get lon(): number | undefined {
    return this._lon;
  }
}
