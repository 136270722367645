import {Pipe} from "@angular/core";

@Pipe({
  name: 'mapToIterable'
})
export class MapToIterablePipe {
  transform(dict: any): Array<any> {
    let a = [];

    if (dict['forEach']) {
      (dict as Map<any, any>).forEach((val, key) => a.push({key, val}));
    } else {
      for (let key in dict) {
        if (dict.hasOwnProperty(key)) {
          a.push({key: key, val: dict[key]});
        }
      }
    }

    return a;
  }
}
