import {Injectable} from "@angular/core";
import {OrderDraft} from "../_models/order-draft";
import {Destination} from "../_models/destination";
import {Account} from "../_models/account";
import {Image} from "../_models/image";
import {environment} from "../../environments/environment";
import { Observable } from "rxjs";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {map} from "rxjs/operators";
import {HttpResponse} from "@angular/common/http";
import {CityService} from "./city.service";
import {DraftsList} from "../_models/drafts-list";
import {DraftListFilter} from "../_models/draft-list-filter";
import {SEARCH_STATUSES_GROUPS} from "../_maps/search-statuses";
import {DateUtils} from "../_utils/date-utils";
import {DraftSpecial} from "../_models/draft-special";

export const PAGE_SIZE = 10;

@Injectable()
export class OrderDraftService {
  constructor(private _requestService: RequestWithErrorHandlerService, private cityService: CityService) { }

  getDrafts(page?: number, filter?: DraftListFilter): Observable<DraftsList> {
    let filterClone = filter && filter.clone() || new DraftListFilter();

    return this._requestService
      .get(`/customer/me/orders/drafts.json`, {
        searchStatus: filter && filter.searchStatus && SEARCH_STATUSES_GROUPS[filter.searchStatus] && SEARCH_STATUSES_GROUPS[filter.searchStatus].statuses.join(',') || null,
        after: filter && filter.after ? DateUtils.formatDate(filter.after) : null,
        before: filter && filter.before ? DateUtils.formatDate(filter.before) : null,
        client: filter && filter.client,
        city: this.cityService.activeCity && this.cityService.activeCity.id,
        offset: (page || 0) * PAGE_SIZE,
        size: PAGE_SIZE,
        view: 'for_orders'
      })
      .pipe(
        map(r => new DraftsList(
          page || 0,
          PAGE_SIZE,
          parseInt(r.headers.get('X-Total-Count') || '0'),
          filterClone,
          r.body.drafts as OrderDraft[])
        )
      );
  }

  getDraft(id: number): Observable<OrderDraft> {
    return this._requestService
      .get(`/customer/me/orders/drafts/${id}.json`, {
        view: 'for_orders'
      })
      .pipe(map(r => r.body.draft as OrderDraft))
      ;
  }


  addDraft(draft: OrderDraft, special?: DraftSpecial): Observable<number> {
    return this._requestService
      .post(`/customer/me/orders/drafts.json`, OrderDraftService.draftToData(draft, special))
      .pipe(
        map(r => r.body.id)
      );
  }

  updateDraft(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/customer/me/orders/drafts/${draft.id}.json`, OrderDraftService.draftToData(draft));
  }

  importComplexDeliveryOrder(draft: OrderDraft, complexDeliveryId: number, complexDeliveryOrderId: string, draftDestinationId?: number, after?: boolean): Observable<HttpResponse<any>> {
    return this._requestService
      .post(`/customer/me/orders/drafts/${draft.id}/importer/complex_delivery/order.json`, {
        complexDelivery: complexDeliveryId,
        complexDeliveryOrder: complexDeliveryOrderId,
        draftDestination: draftDestinationId,
        after: after ? 1 : 0
      });
  }

  updateNotes(id: number, notes: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/customer/me/orders/drafts/${id}/notes.json`, {
        notes
      });
  }

  updateSearchParams(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/customer/me/orders/drafts/${draft.id}/search_params.json`, {
        autoAcceptTaxi: draft.auto_accept_taxi,
        autoExternalSearch: draft.auto_external_search
      });
  }

  updateIntercomDialog(id: number, link: string): Observable<HttpResponse<any>> {
    return this._requestService
      .put(`/customer/me/orders/drafts/${id}/intercom_dialog.json`, {
        link
      });
  }

  scheduleDelivery(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/customer/me/orders/drafts/${draft.id}/delivery/schedule.json`, {});
  }

  startSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/customer/me/orders/drafts/${draft.id}/taxi/search.json`, {});
  }

  restartSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/customer/me/orders/drafts/${draft.id}/taxi/search/restart.json`, {});
  }

  startSearchViaLinks(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/support/me/orders/drafts/${draft.id}/taxi/search_via_links.json`, {});
  }

  stopSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/customer/me/orders/drafts/${draft.id}/taxi/search/stop.json`, {});
  }

  pauseSearch(draft: OrderDraft): Observable<HttpResponse<any>> {
    return this._requestService.post(`/customer/me/orders/drafts/${draft.id}/taxi/search/pause.json`, {});
  }

  deletePhoto(draft: OrderDraft, image: Image): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/customer/me/orders/drafts/${draft.id}/photos.json`, {
      filename: image.filename
    });
  }

  getLinkForEmployer(draft: OrderDraft): string {
    return `${environment.apiEndpoint}/employer/me/drafts/${draft.id}.html`;
  }

  requestCalculation(draft: OrderDraft, special?: DraftSpecial): Observable<any> {
    return this._requestService
      .post(
        '/orders/calculator/taxi.json',
        Object.assign(OrderDraftService.draftToData(draft, special), { points: false, withCalculation: true })
      )
      .pipe(map(r => r.body))
      ;
  }

  requestMultiCalculation(draft: OrderDraft, variants: any, special?: DraftSpecial): Observable<any[]> {
    return this._requestService
      .post(
        '/orders/calculator/taxi/multi.json',
        Object.assign(OrderDraftService.draftToData(draft, special), { variants })
      )
      .pipe(map(r => r.body as any[]))
      ;
  }

  private static draftToData(draft: OrderDraft, special?: DraftSpecial): any {
    let data: any = {
      taxi: 1,
      nearest: 0,
      city: draft.city && draft.city.id,
      delivery: draft.delivery ? 1 : 0,
      payMethod: draft.pay_method,
      payMethodOption: draft.pay_method_option,
      loaders: draft.loaders,
      assembly: draft.assembly,
      comment: draft.comment,
      client: OrderDraftService.clientToData(draft.client!),
      legalEntity: draft.legal_entity ? draft.legal_entity.id : null,
      deliverySchema: draft.delivery_schema && draft.delivery_schema.id,
      deliveryCompanyClient: draft.delivery_company_client && draft.delivery_company_client.id,
      deliveryStorehouseArrival: draft.delivery_storehouse_arrival && draft.delivery_storehouse_arrival !== ''
        ? OrderDraftService.convertDate(new Date(draft.delivery_storehouse_arrival))
        : null
    };

    if(special) {
      data.special = special.special;
      data.specialSchema = special.schema;
    }

    OrderDraftService.autoAcceptTaxiToData(draft, data);
    OrderDraftService.autoExternalSearchToData(draft, data);
    OrderDraftService.draftFeaturesToData(draft, data);
    OrderDraftService.draftExtraToData(draft, data);
    OrderDraftService.autoAssigmentCrewToData(draft, data);

    this.draftTariffsToData(draft, data);

    if(draft.destinations!.length > 0)
      data['from'] = OrderDraftService.destinationToData(draft.destinations![0]);

    if(draft.destinations!.length > 1)
      data['to'] = draft.destinations!.slice(1).map(OrderDraftService.destinationToData);

    return data;
  }

  private static draftTariffsToData(draft: OrderDraft, data: any): void {
    if(draft.tariffs && draft.tariffs.length > 0) {
      let tariff = draft.tariffs[0];
      data.tariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        afterMkadKmPrice: tariff.after_mkad_km_price,
        ttkPrice: tariff.ttk_price,
        hydroelevatorHourPrice: tariff.hydroelevator_hour_price
      };
    }
    if(draft.loader_tariffs && draft.loader_tariffs[0]) {
      let tariff = draft.loader_tariffs[0];
      data.loaderTariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price,
        onTheWayHourPrice: tariff.on_the_way_hour_price
      };
    }
    if(draft.assembler_tariffs && draft.assembler_tariffs[0]) {
      let tariff = draft.assembler_tariffs[0];
      data.assemblerTariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price
      }
    }
    if(draft.lifting_tariffs && draft.lifting_tariffs[0]) {
      let tariff = draft.lifting_tariffs[0];
      data.liftingTariff = {
        minPrice: tariff.min_price,
        minHours: tariff.min_hours,
        additionalHourPrice: tariff.additional_hour_price
      }
    }
  }

  private static autoAssigmentCrewToData(draft: OrderDraft, data: any): void {
    if(draft.auto_assigment_crew != null)
      data['autoAssigmentCrew'] = draft.auto_assigment_crew;
  }

  private static autoAcceptTaxiToData(draft: OrderDraft, data: any): void {
    data.autoAcceptTaxi = draft.auto_accept_taxi;
  }

  private static autoExternalSearchToData(draft: OrderDraft, data: any): void {
    data.autoExternalSearch = draft.auto_external_search;
  }

  /**
   * Запись свойств черновика в объект данных запроса
   *
   * @param {OrderDraft} draft
   * @param {any} data
   */
  private static draftFeaturesToData(draft: OrderDraft, data: any) {
    if(!draft.features)
      return;

    data['features'] = {};
    for(let feature of draft.features) {
      data['features'][feature.feature!.identifier!] = {};

      data['features'][feature.feature!.identifier!]['value'] = feature.value;
      data['features'][feature.feature!.identifier!]['count'] = feature.count;
    }
  }

  private static destinationToData(destination: Destination): any {
    return {
      id: destination.id,
      addr: destination.destination!.addr,
      lat: destination.destination!.lat,
      lon: destination.destination!.lon,
      elevator: destination.elevator,
      lifting: destination.lifting,
      floor: destination.floor,
      loading: destination.loading,
      unloading: destination.unloading,
      unloadingInternetShopOrder: destination.unloading_internet_shop_order,
      requiredAdditionalTime: destination.required_additional_time,
      deliveryTo: destination.delivery_to,
      contactName: destination.contact_name,
      contactPhone: destination.contact_phone,
      contactSendSms: destination.contact_send_sms,
      arrivalTime: destination.arrival_time ? OrderDraftService.convertDate(new Date(destination.arrival_time)) : null,
      comment: destination.comment,
      clientLegalEntity: destination.client_legal_entity && destination.client_legal_entity.id || null
    };
  }

  /**
   * Запись дополнительных параметров поиска в объект данных для запроса
   *
   * @param {OrderDraft} draft
   * @param {any} data
   */
  private static draftExtraToData(draft: OrderDraft, data: any) {
    if(!draft.extra_search_params)
      return;

    data['extra'] = {};

    if(draft.extra_search_params.tariff_tier)
      data['extra']['tier'] = draft.extra_search_params.tariff_tier.identifier;

    data['extra']['lengthGroups'] = draft.extra_search_params.length_groups ? draft.extra_search_params.length_groups.join(',') : '';
  }

  private static clientToData(client: Account): any {
    if(!client.phone || client.phone === '')
      return null;

    return {
      phone: client.phone,
      name: client.name,
      surname: client.surname,
      patronymic: client.patronymic
    }
  }

  private static convertDate(date: Date): string {
    return DateUtils.convertDate(date);
  }
}
