import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {JivoSiteService} from "../_services/jivo-site.service";
import {IFrameResizerService} from "../_services/i-frame-resizer.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {

  private chatVisibleSubscription: Subscription|undefined;

  constructor(
    private jivoSiteService: JivoSiteService,
    private iframeResizerService: IFrameResizerService,
    private router: Router,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.iframeResizerService.fixIframeSize();
    setTimeout(() => {
      this.jivoSiteService.show();
    }, 1000);

    this.chatVisibleSubscription = this.jivoSiteService.getVisibilityObservable().subscribe(visibility => this.onChangeChatVisibility(visibility));
  }

  private onChangeChatVisibility(visibility: boolean): void {
    if(!visibility)
      this.onCloseChat();
  }

  private onCloseChat(): void {
    this.iframeResizerService.unfixIframeSize();
    setTimeout(() => {
      this.zone.run(() => this.router.navigate(['/draft']));
    }, 1000);
  }

  ngOnDestroy(): void {
    this.chatVisibleSubscription?.unsubscribe();
    this.chatVisibleSubscription = undefined;
  }
}
