import {Injectable} from "@angular/core";

@Injectable()
export class LocalStorageService {
  private readonly browserMode: boolean;
  private serviceStorage = new Map<string, string>();

  constructor() {
    try {
      this.browserMode = localStorage && !localStorage.getItem('browserMode');
    }
    catch(e) {
      console.log('Use a Map instead the localStorage');
      this.browserMode = false;
    }
  }

  clear(): void {
    if(this.browserMode)
      localStorage.clear();
    else
      this.serviceStorage.clear();
  }

  getItem(key: string): string | null {
    return this.browserMode ? localStorage.getItem(key) : (this.serviceStorage.get(key) || null);
  }

  removeItem(key: string): void {
    if(this.browserMode)
      localStorage.removeItem(key);
    else
      this.serviceStorage.delete(key);
  }

  setItem(key: string, value: string): void {
    if(this.browserMode)
      localStorage.setItem(key, value);
    else
      this.serviceStorage.set(key, value);
  }
}
