import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

export enum RegisterResult {
  Ok,
  InvalidPhone,
  UserExists,
  SystemError
}

@Injectable()
export class RegisterAccountService {
  constructor(private _requestService: RequestWithErrorHandlerService) {
  }

  registerAccount(phone: string): Observable<RegisterResult> {
    return this._requestService
      .post('/registration/phone.json', { phone })
      .pipe(
        map(() => RegisterResult.Ok),
        catchError(e => {
          if(e instanceof HttpErrorResponse) {
            switch(e.status) {
              case 400:
                return of(RegisterResult.InvalidPhone);
              case 409:
                return of(RegisterResult.UserExists);
              default:
                return of(RegisterResult.SystemError);
            }
          } else {
            return throwError(e);
          }
        })
      )
    ;
  }
}
