<div class="right_preview_address">
  <div class="item_address" *ngFor="let point of initOrder.points; let i = index">
    <div class="icon_address" [class.icon1]="i == 0" [class.icon2]="i > 0"></div>
    <address-field [(address)]="initOrder.points[i]"
                   (addressChange)="onChangeAddress()"
                   [autoFocus]="i == 0"></address-field></div>
</div>
<div class="right_preview_tariff" [class.not-visible]="tier.length == 0">
  <div class="tariff_folded_open" [class.tariff_folded_open_active]="tierData.identifier == initOrder.tierIdentifier" *ngFor="let tierData of tierDescriptors">
    <div class="tariff_folded_open_top" (click)="onSelectTier(tierData)">
      <div class="left_tariff_folded">
        <div [className]="tierData.iconClass"></div>
        <div class="title_ltf">{{ tierData.name }}</div>
        <div class="text_ltf">{{ tierData.tonnage }}</div>
        <div class="text_ltf">{{ tierData.volume }}</div>
      </div>
      <div class="right_tariff_folded" *ngFor="let calculation of [getCalculationByTierIdentifier(tierData.identifier)]">
        <div class="prices_rtf" *ngIf="calculation">{{ calculation.price.total_price }}</div>
        <div class="time_rtf">Р</div>
        <div class="icon_rtf"></div>
      </div>
    </div>
    <div class="box_info_tariff_folded_open">
      <div class="tariff_folded_open_info">
        <div class="tfpi_photo" [style]="'background-image: url(/assets/img/tariffs/' + tierData.image + ') !important;'"></div>
        <div class="tfpi_box">
          <div class="tfpi_box_bold">{{ tierData.description }}</div>
          <div class="tfpi_box_regular">
            <span *ngFor="let body of tierData.bodies; last as isLast">{{ body }}<span *ngIf="!isLast">&nbsp;•&nbsp;</span></span>
          </div>
        </div>
      </div>
      <div class="tariff_folded_open_midlle">
        <div class="tfom_box">
          <div class="tfom_box_icon tbi_icon1"></div>
          <div class="tfom_box_text">{{ tierData.volumeSubscription }}</div>
        </div>
        <div class="tfom_box">
          <div class="tfom_box_icon tbi_icon2"></div>
          <div class="tfom_box_text">{{ tierData.lengthSubscription }}</div>
        </div>
      </div>
      <div class="tariff_folded_open_bottom" *ngFor="let calculation of [getCalculationByTierIdentifier(tierData.identifier)]">
        <div class="tfob_box_time" *ngIf="calculation">
          <div class="tfob_box_time_icon"></div>
          <div class="tfob_box_time_text">
            Подача {{ calculation.arrival_estimate }} мин<br>
            {{ calculation.cars_found.length }} экипажей
          </div>
        </div>
        <a
          class="tfob_box_btn"
          routerLink="/draft"
          [queryParams]="getCreateDraftParams()"
          >Далее</a>
      </div>
    </div>
  </div>
</div>
