<div class="privacy-policy">
  <h4>Политика конфиденциальности</h4>
  <p>Настоящая «Политика конфиденциальности» представляет собой правила использования ООО “8РХ” в лице Генерального
    Директора Мегерян Амаяк Людвигович, действующего на основании Устава, зарегистрированной по праву РФ (далее —
    «Mover», «Администрация») персональной информации Пользователя.</p>
  <h4>1. Общие положения</h4>
  <p>1.1. Настоящая Политика является неотъемлемой частью Пользовательского соглашения (далее — «Соглашение»),
    размещенного и/или доступного в сети Интернет по адресу: www.mover24.ru/legal, а также иных заключаемых с
    Пользователем договоров, когда это прямо предусмотрено их условиями.</p>
  <p>1.2. Настоящие Правила вступают в силу с момента принятия Пользователем условий настоящих Правил в результате
    прохождения процедуры регистрации путем заполнения регистрационной формы, размещенной в Мобильном приложении или на
    Сайте и его поддоменах (далее – «Сервис»), свидетельствующей о принятии условий настоящих Правил. Настоящие правила
    не распространяются на информацию, собираемую нами у водителей, партнерских транспортных компаний или иных лиц,
    использующих Сервис по сублицензии (совместно именуемых «Поставщик услуг»).</p>
  <p>1.3. Заключая Соглашение Пользователь дает бессрочное безотзывное письменное согласие на любые способы обработки
    своих персональных данных, включая любое действие (операцию) или совокупность действий (операций), совершаемых с
    использованием средств автоматизации или без использования таких средств с персональными данными, в том числе сбор,
    запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
    (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных в
    установленных настоящей Политикой целях.</p>
  <p>1.4. Обработка персональных данных Пользователя осуществляется с соблюдением федерального закона от 27 июля 2006
    года № 152-ФЗ «О персональных данных» и иных нормативно-правовых актов, регулирующих защиту персональных данных.</p>
  <p>1.5. Персональные данные Пользователя, касающиеся расовой принадлежности, политических взглядов, религиозных и
    философских убеждений, состояния здоровья, интимной жизни не получаются и не обрабатываются.</p>
  <p>1.6. Исходя из пункта 5 части 1 статьи 6 Федерального закона Российской Федерации «О персональных данных»,
    обработка персональных данных осуществляется на основании заключенного договора с Пользователем.</p>

  <h4>2. Получение данных Пользователя</h4>
  <p>2.1. Используя Сервис Администрация в автоматическом режиме собирает информацию о Пользователях, строго соблюдая и
    следуя принципам конфиденциальности. Пользователь соглашается, что Администрацией может быть сохранено несколько
    общих уровней информации о посещении:</p>
  <p>2.1.1. Первый уровень информации включает в себя статическую и прочую обезличенную аналитическую информацию,
    собираемую на основе средств интеграции со сторонними ресурсами (например: IP-адрес, тип используемого браузера,
    информацию об операционной системе компьютера, версии приложения, языковых настройках и демонстрируемых
    страницах.)</p>
  <p>2.1.2. Второй уровень информации - личная или персональная информация лиц, направленная в адрес Администрации.
    Посредством Сервиса Пользователь можете отправить информацию со страниц «Регистрации», такую как: имя, номер
    телефона, адрес. Также данные, идентифицирующие мобильное устройство Пользователя, его специфические настройки и
    характеристики, информацию о широте/долготе.</p>
  <p>2.2. В целях надлежащего функционирования Приложение Mover Пользователю необходимо предоставить согласие через
    «систему разрешения доступа» к услугам мобильной операционной системы (платформы): таким как геолокация, адресная
    книга, информации о звонках и SMS-сообщениях, данных журнала.</p>
  <p>2.3. Пользователь, используя Приложение, даёт согласие на сбор данных о своём местоположении в фоновом режиме, для
    активации функций по отображению доступных
    исполнителей и заказов поблизости, отображению своего маршрута следования для исполнителей и его отслеживанию для
    заказчиков, функционал работает, даже если
    приложение закрыто или не используется.</p>
  <p>2.4. Персональная информация будет использована Администрацией исключительно в следующих целях:</p>
  <p>2.4.1. Заключения Соглашения на использование Сервиса;</p>
  <p>2.4.2. Исполнения обязательств по заключенным договорам с Поставщиком услуг, включая предоставление Пользователю
    доступа к заказу грузовых транспортных средств и информации о месте его нахождения;</p>
  <p>2.4.3.Идентификации Пользователя в рамках исполнения обязательств по заключенным с ним договорам;</p>
  <p>2.4.4.Оказания технической поддержки в связи с использованием Сервиса</p>
  <p>2.4.5.Использование обезличенных данных для таргетинга рекламных и/или информационных материалов по возрасту, полу,
    другим признакам;</p>
  <p>2.4.6.Проведение маркетинговых, статистических и иных исследований на основе обезличенных данных.</p>
  <p>2.5 Администрация Сервиса собирает лишь информацию, которую Исполнитель сделал доступной. Администрация может
    хранить и использовать эту информацию для целей, описанных выше.</p>
  <p>2.6. Администрация может собирать и обобщать Личную информацию о вашей деятельности. Данная информация защищена
    паролем и доступна лишь сотрудникам.</p>
  <p>2.7. При доступе пользователя на компьютер или мобильные устройства, используемые им для доступа, могут быть
    записаны файлы cookies, которые в дальнейшем будут использованы для автоматической авторизации Пользователя, а также
    для сбора статистических данных</p>

  <h4>3. Передача данных третьим лицам.</h4>
  <p>3.1. Администрация может передавать Ваши персональные данные сторонним поставщикам услуг в целях упрощения процесса
    заключения договора перевозки груза с партнером, аренды транспортных средств или погрузо-разгрузочных работ.</p>
  <p>3.2. Партнеры и/или поставщики услуг связаны соглашениями о конфиденциальности с Mover и не имеют права
    использовать Ваши персональные данные в своих целях или в любых других целях.</p>
  <p>3.3. Передаваемые данные могут включать: имя Пользователя, адрес забора/доставки груза, контактные данные, и всю
    дополнительную информацию, которую Вы указали при формировании заказа.</p>

  <h4>4. Хранение персональных данных Пользователя</h4>
  <p>4.1. Персональные данные Пользователя хранятся в электронном виде.</p>
  <p>4.2. В случае окончания срока действия или расторжения договора с Пользователем осуществляется блокирование
    персональных данных Пользователя в соответствии с требованиями законодательства РФ.</p>
  <p>4.3. В соответствии с требованиями законодательства РФ уничтожение персональных данных Пользователя осуществляется
    по истечении 10 лет с момента окончания срока действия или расторжения договора с Пользователем. Уничтожение
    персональных данных Пользователя до истечения указанного срока может быть произведено на основании письменного
    заявления Пользователя.</p>

  <h4>5. Организация защиты персональных данных Пользователя</h4>
  <p>5.1. Персональные данные Пользователя подлежат защите от несанкционированного доступа и распространения в
    соответствии с внутренними правилами и регламентами.</p>
  <p>5.2. Защиты персональных данных Пользователя обеспечивается:</p>
  <ul>
    <li>предотвращением несанкционированного доступа к обрабатываемым персональным данным Пользователя;</li>
    <li>предотвращением несанкционированных действий по модификации, искажению, распространению, блокированию,
      уничтожению обрабатываемых персональных данных Пользователя;
    </li>
    <li>обеспечением конфиденциальности обрабатываемых персональных данных Пользователя.</li>
  </ul>
  <p>5.3. Администрация осуществляет защиту персональных данных, за исключением случаев, когда Пользователь осуществляет
    открытый обмен информацией с Партнером или с любыми пользователям сети Интернет.</p>
  <p>5.4. Администрация вправе передавать Персональную информацию третьим лицам в следующих случаях:</p>
  <p>5.4.1. Пользователь выразил свое согласие на такие действия, включая случаи применения Пользователем настроек
    используемого программного обеспечения, не ограничивающих предоставление определенной информации;</p>
  <p>5.4.2. По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством
    процедуры.</p>

  <h4>6. Изменение Персональной информации</h4>
  <p>6.1. Пользователь вправе в любой момент самостоятельно отредактировать в своем Личном кабинете предоставленную им
    при регистрации или авторизации Персональную информацию.</p>
  <p>6.2. В случае прекращения заключенного Пользовательского Соглашения, Пользователь вправе удалить собственную
    учетную запись либо реализовать право на отзыв согласия на обработку его персональных данных путем направления
    запроса на удаление своей учетной записи по адресу support@mover24.ru</p>

  <h4>7. Изменение Политики конфиденциальности</h4>
  <p>Настоящая Политика конфиденциальности может быть изменена в одностороннем порядке без предварительного уведомления
    Пользователя. Новая редакция Политики вступает в силу с момента ее размещения в Сервисе.</p>
</div>
