export const LIFT_TYPES: any = {
  'no-move': 'не нужно переносить груз',
  'up-with-lift': 'поднять груз на лифте',
  'up-without-lift': 'поднять груз без лифта',
  'down-with-lift': 'спустить груз на лифте',
  'down-without-lift': 'спустить груз без лифта'
};

export const LIFT_TYPES_TO_DESTINATION_MAP: any = {
  'no-move': { lifting: false, elevator: false },
  'up-with-lift': { lifting: false, elevator: true },
  'up-without-lift': { lifting: true, elevator: false },
  'down-with-lift': { lifting: false, elevator: true },
  'down-without-lift': { lifting: true, elevator: false },
}
