import {TierDescriptor} from "../_models/tier-descriptor";

export const TIER_DESCRIPTORS: { [key: string]: TierDescriptor } = {
  mini2: new TierDescriptor(
    'Мини',
    'mini2',
    '0,7 т',
    '3м3',
    '3м3',
    '1,5 м.',
    'Поместится маленькая мебель, электроника или велосипеды',
    [ 'Фургон', 'Цельнометаллический' ],
    'mini2_active@2x.png',
    'icon_ltf1',
    '1.5 м',
    '1 м',
    '1 м',
    '3 м³',
    '0,7 т',
  ),
  standart2: new TierDescriptor(
    'Стандарт',
    'standart2',
    '1 т',
    '9м3',
    '9м3',
    '2,5 м.',
    'Поместятся крупные предметы мебели, бытовая и офисная техника',
    [ 'Фургон', 'Цельнометаллический', 'Тент', 'Фермер Фургон', 'Бортовая' ],
    'standart2_active@2x.png',
    'icon_ltf2',
    '2.5 м',
    '1.5 м',
    '1.8 м',
    '9 м³',
    '1 т',
  ),
  extra2: new TierDescriptor(
    'Экстра',
    'extra2',
    'до 1.5 т',
    '14м3',
    '14м3',
    '4 м.',
    'Подходит для большого переезда, перевозки стройматериалов и промышленного оборудования',
    [ 'Фургон', 'Тент', 'Цельнометаллический', 'Фермер Тент' ],
    'extra2_active@2x.png',
    'icon_ltf3',
    '4 м',
    '1.8 м',
    '2 м',
    '14 м³',
    '1,5 т',
  ),
  maxi2: new TierDescriptor(
    'Макси',
    'maxi2',
    'до 3 т',
    '26м3',
    'до 26м3',
    '4.2 м.',
    'Большой грузовик от 4 м. с профессиональным водителем',
    [ 'Фургон' ],
    'maxi2_active@2x.png',
    'icon_ltf4',
    '4 м',
    '2 м',
    '2 м',
    '19 м³',
    '3 т',
  )
}
