import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BankCard} from "../_models/bank-card";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {map} from "rxjs/operators";
import {AddBankCardState} from "../_models/add-bank-card-state";
import {environment} from "../../environments/environment";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class BankCardService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getMyCards(): Observable<BankCard[]> {
    return this._requestService
      .get(`/me/cards.json`)
      .pipe(
        map(r => r.body.cards as BankCard[])
      );
  }

  /**
   * Регистрация запроса на добавление карты
   *
   * @return request id
   */
  doAddCardRequest(): Observable<string> {
    return this._requestService
      .post('/me/cards/request.json', { provider: 'alfa_bank' })
      .pipe(
        map(r => r.body.request_id as string)
      );
  }

  requestAddCardState(requestId: string): Observable<AddBankCardState> {
    return this._requestService
      .get(`/me/cards/request/${requestId}.json`)
      .pipe(
        map(r => r.body as AddBankCardState)
      );
  }

  openAddCardPage(accessToken: string): void {
    window.location.href = `${environment.apiEndpoint}/cards/request/${accessToken}/start?returnMode=${environment.addBankCardReturnMode}`
  }

  removeCard(card: BankCard): Observable<HttpResponse<any>> {
    return this._requestService.delete(`/me/cards/${card.id}.json`);
  }
}
