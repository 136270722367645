<div class="ng-autocomplete">
  <ng-autocomplete
    [class]="class"
    [data]="data"
    [searchKeyword]="keyword"
    (selected)='selectEvent($event)'
    (inputFocused)='onFocused()'
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [isLoading]="isLoading"
    [debounceTime]="300"
    [customFilter]="customFilter"
    [minQueryLength]="4"
    [placeholder]="placeholder"
    [name]="name"
    [(ngModel)]="value"
    (ngModelChange)="onChangeSearch($event)"
    (inputCleared)="onCleared()"
    [disabled]="disabled"
  >
  </ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <a [innerHTML]="item.formated_address"></a>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="'Адрес не найден'" *ngIf="!isLoading"></div>
    <div [innerHTML]="'Загрузка...'" *ngIf="isLoading"></div>
  </ng-template>
</div>
