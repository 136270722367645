import {State} from "../_storage/state";
import {DateUtils} from "../_utils/date-utils";

export class DraftListFilter {
  constructor(public searchStatus: string = '', public after?: Date, public before?: Date, public client?: string) {}

  getState(): State {
    return new State(JSON.stringify({
      searchStatus: this.searchStatus,
      after: this.after,
      before: this.before,
      client: this.client
    }));
  }

  equals(filter: DraftListFilter): boolean {
    let result = this.searchStatus === filter.searchStatus
      && this.client === filter.client
      && DateUtils.isEqualDates(this.after, filter.after)
      && DateUtils.isEqualDates(this.before, filter.before)
    ;

    console.log(`${this.client} == ${filter.client}`);
    console.log(result);

    return result;
  }

  clone(): DraftListFilter {
    return new DraftListFilter(this.searchStatus, this.after, this.before, this.client);
  }

  static fromState(state: State): DraftListFilter {
    let data = JSON.parse(state.state);
    let searchStatus = data.searchStatus || "";
    let after = data.after == undefined ? undefined : new Date(data.after);
    let before = data.before == undefined ? undefined : new Date(data.before);
    let client = data.client;

    return new DraftListFilter(searchStatus, after, before, client);
  }
}
