import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {TokenService} from "./token.service";

@Injectable()
export class RequestService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {}

  get(uri: string, search?: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${environment.apiEndpoint}${uri}`, {
      headers: this.createHeaders(),
      params: search ? new HttpParams({
        fromObject: RequestService.filterParams(search)
      }) : undefined,
      withCredentials: true,
      observe: 'response'
    });
  }

  post(uri: string, data: Object): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiEndpoint}${uri}`, data, {
      headers: this.createHeaders(),
      withCredentials: true,
      observe: 'response'
    });
  }

  put(uri: string, data: Object): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${environment.apiEndpoint}${uri}`, data, {
      headers: this.createHeaders(),
      withCredentials: true,
      observe: 'response'
    });
  }

  delete(uri: string, params?: any): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${environment.apiEndpoint}${uri}`, {
      headers: this.createHeaders(),
      params: params ? new HttpParams({
        fromObject: RequestService.filterParams(params)
      }) : undefined,
      withCredentials: true,
      observe: 'response'
    });
  }

  protected createHeaders(): HttpHeaders {
    let headers: {
      [name: string]: string | string[];
    } = {
      'Content-Type': 'application/json',
    };

    if(this.tokenService.token)
      headers['X-Token'] = this.tokenService.token;
    if(!environment.production)
      headers['X-Test'] = 'true';

    return new HttpHeaders(headers);
  }

  private static filterParams(params: any[string]): any[string] {
    let filtered: any[string] = {};
    for(let key in params) {
      if(params.hasOwnProperty(key) && params[key] != null)
        filtered[key] = params[key];
    }
    return filtered;
  }
}
