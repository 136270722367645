import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DraftStorageService} from "../_services/draft-storage.service";
import {IFrameResizerService} from "../_services/i-frame-resizer.service";
import {JivoSiteService} from "../_services/jivo-site.service";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
  private faqSections = new Map<number, boolean>();

  constructor(
    private router: Router,
    private draftStorageService: DraftStorageService,
    private iFrameResizerService: IFrameResizerService,
    private jivoSiteService: JivoSiteService
  ) {}

  ngOnInit(): void {
    this.initIframeHandlers();
  }

  ngOnDestroy(): void {
    this.destroyIframeHandlers();
  }

  private initIframeHandlers(): void {
    this.iFrameResizerService.addPageInfoListener('index', info => this.onReceivedPageInfo(info));
  }

  private destroyIframeHandlers(): void {
    this.iFrameResizerService.removePageInfoListener('index');
  }

  private toggleFaqSection(section: number): void {
    this.faqSections.set(section, !this.isFaqSectionOpen(section));
  }

  isFaqSectionOpen(section: number): boolean {
    return this.faqSections.get(section) || false;
  }

  onCreateDraft(): void {
    this.router.navigate([`/draft`]);
  }

  onReceivedPageInfo(info: any): void {
    this.destroyIframeHandlers();

    console.log('new draft state:', this.draftStorageService.isNewDraftActive());
    if(this.draftStorageService.isNewDraftActive()) {
      console.log('navigate to /draft');
      this.router.navigate([`/draft`]);
    }
  }

  onToggleFaqSection(section: number): void {
    this.toggleFaqSection(section);
  }

  onOpenChat(): void {
    this.jivoSiteService.show();
  }
}
