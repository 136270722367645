import {Injectable} from "@angular/core";
import {HttpErrorHandlerService} from "./http-error-handler.service";
import {Observable} from "rxjs";
import {RequestService} from "./request.service";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class RequestWithErrorHandlerService {
  constructor(private requestService: RequestService, private _errorHandlerService: HttpErrorHandlerService) {}

  get(uri: string, search?: any): Observable<HttpResponse<any>> {
    return this._errorHandlerService.addHandler(this.requestService.get(uri, search));
  }

  post(uri: string, data: Object): Observable<HttpResponse<any>> {
    this.errorHandlerService.clear();
    return this._errorHandlerService.addHandler(this.requestService.post(uri, data));
  }

  put(uri: string, data: Object): Observable<HttpResponse<any>> {
    this.errorHandlerService.clear();
    return this._errorHandlerService.addHandler(this.requestService.put(uri, data));
  }

  delete(uri: string, params?: any): Observable<HttpResponse<any>> {
    this.errorHandlerService.clear();
    return this._errorHandlerService.addHandler(this.requestService.delete(uri, params));
  }

  get errorHandlerService(): HttpErrorHandlerService {
    return this._errorHandlerService;
  }
}
