<div class="terms-and-definitions">
  <h4>Термины и определения</h4>

  <p><b>Онлайн-сервис «MOVER»</b>– интернет-сайт mover24.ru и мобильное приложение «MOVER» на платформах iOS и Android,
    представляющие платформу для приёма передачи (переадресации) Заказов, позволяющие осуществлять быстрое удаленное
    взаимодействие клиента с грузоперевозчиками, а также предоставляющие иные функциональные возможности, полное
    описание которых приведено в руководстве по использованию сервиса.</p>
  <p><b>Приложение для водителей</b>– часть Онлайн-сервиса «MOVER», предназначенная для Водителей, выполняющая функции
    автоматизированного приёма Заказов их распределения между Водителями, а также предоставляющая иные функциональные
    возможности для Водителей, полное описание которых приведено в руководстве по использованию сервиса.</p>
  <p><b>ООО «8PX»</b>– посредник, по поручению другой стороны (Грузоперевозчика) совершающий юридические и иные действия
    от своего имени, но за счет Грузоперевозчика либо от имени и за счет Грузоперевозчика, в том числе оказывающий
    содействие в установлении контактов и заключении сделок между Грузоотправителем и Грузоперевозчиком, соединении
    Грузоотправителя и Водителя, предоставляет онлайн-площадку для принятия заказов на грузоперевозку и их передачи
    Грузоперевозчику, либо заказывающий соответствующие услуги от своего имени для третьих лиц.</p>
  <p><b>Грузоперевозчик</b>– юридическое лицо или индивидуальный предприниматель, которое(-ый) заключает настоящий
    Договор с ООО «8PX», владеет парком грузовых автомобилей, организует водителей для непосредственного выполнения
    заказа в соответствие с настоящим Договором, либо индивидуальный предприниматель – Водитель, заключающий настоящий
    Договор и лично исполняющий заказы.</p>
  <p><b>Водитель</b>– лицо, зарегистрированное в качестве индивидуального предпринимателя, или физическое лицо,
    обладающее правом на управление грузовым транспортным средством и осуществляющее фактическое управление грузовым
    транспортным средством при осуществлении грузоперевозки в рамках настоящего Договора, являющееся Грузоперевозчиком,
    сотрудником Грузоперевозчика или надлежащим образом уполномоченным лицом, состоящим в гражданско-правовых отношениях
    с Грузоперевозчиком.</p>
  <p><b>Регистрация</b>– предоставление данных ООО «8PX» и последующее формирование уникальных авторизационных данных в
    Онлайн-сервисе «MOVER».</p>
  <p><b>Заказ</b>– запрос Грузоперевозчику на оказание ему услуги по перевозке грузов и/или предоставлению за плату в
    пользование и во владение на определенный срок транспортных средств c экипажем, формируемый Клиентом через
    Онлайн-сервис «MOVER», в котором указываются существенные условия договора и принятие к исполнению которого означает
    согласие Грузоперевозчика со всеми указанными в нём условиями.</p>
  <p><b>Клиент, Грузоотправитель</b>– физическое или юридическое лицо, которое заинтересовано в услуге по перевозке
    грузов и/или в получении за плату в пользование и во владение на определенный срок транспортное средство c экипажем
    для перевозки груза и формирующее Заказ через Онлайн-сервис «MOVER», уполномочившее ООО «8PX» осуществлять
    деятельность по поиску Грузоперевозчиков и, если это предусмотрено договором, заключению с ними договоров перевозки.
    В качестве Грузоотправителя может выступать ООО «8PX».</p>
</div>
