import {Injectable} from "@angular/core";
import {UserInfoService} from "./user-info.service";
import {UserNameUtils} from "../_utils/user-name-utils";
import {UserInfo} from "../_models/user-info";
import {LogoutService} from "./logout.service";
import {Observable, Subject, Subscription} from "rxjs";

@Injectable()
export class JivoSiteService {
  chatUrl = '//code-sb1.jivosite.com/widget/Zmqdh41vjT';

  private _isVisible = false;

  private logoutSubscription: Subscription|undefined;

  private visibilitySubject = new Subject<boolean>();

  constructor(private userInfoService: UserInfoService, private logoutService: LogoutService) {
  }

  setupUserData(): void {
    if(!(<any>window)['jivo_api']) {

      console.log('Wait for a JivoSite chat initialization...');
      setTimeout(() => this.setupUserData(), 5000);

    } else {

      this.userInfoService.getUserLoginSubject().subscribe(
        userInfo => {
          this.rawSetupUserData(userInfo);
        }
      );

      if(this.userInfoService.isPresent() && this.userInfoService.userInfo)
        this.rawSetupUserData(this.userInfoService.userInfo);
    }
  }

  getVisibilityObservable(): Observable<boolean> {
    return this.visibilitySubject.asObservable();
  }

  private rawSetupUserData(userInfo: UserInfo): void {
    if(userInfo.account) {
      console.log('Setup contact info for a JivoSite chat');

      (<any>window)['jivo_api'].setContactInfo({
        name: UserNameUtils.SNPToName(userInfo.account),
        email: userInfo.account.email,
        phone: userInfo.account.phone
      });

      if(userInfo.account.company_client) {
        let { id, name } = userInfo.account.company_client;
        (<any>window)['jivo_api'].setCustomData([{
          title: 'Id компании',
          content: "" + id
        },{
          title: 'Наименование компании',
          content: name
        }]);
      }

      this.logoutSubscription = this.logoutService.getUserLogoutObservable().subscribe(() => this.logout());
    }
  }

  logout(): void {
    (<any>window)['jivo_api']?.clearHistory();

    this.logoutSubscription?.unsubscribe();
    this.logoutSubscription = undefined;
  }

  show(): void {
    if(!this._isVisible) {
      this._isVisible = true;
      this.visibilitySubject.next(true);
    }
  }

  hide(): void {
    if(this._isVisible) {
      this._isVisible = false;
      this.visibilitySubject.next(false);
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
}
