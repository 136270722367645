import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {JivoSiteService} from "../_services/jivo-site.service";
import {DOCUMENT} from "@angular/common";
import {Subscription} from "rxjs";

// !!! данная реализация не предусматривает пересоздание компонента !!!
@Component({
  selector: 'jivo-site',
  templateUrl: './jivo-site.component.html',
  styleUrls: ['./jivo-site.component.css']
})
export class JivoSiteComponent implements OnInit, OnDestroy {
  private visibilitySubscription: Subscription|undefined;
  private isVisible: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private jivoSiteService: JivoSiteService
  ) {
    this.isVisible = jivoSiteService.isVisible;
  }

  ngOnInit() {
    this.visibilitySubscription = this.jivoSiteService.getVisibilityObservable().subscribe(visibility => this.onChangeVisibility(visibility));

    const widgetScript = this.renderer.createElement( 'script');
    widgetScript.src = this.jivoSiteService.chatUrl;
    this.renderer.appendChild(this.document.body, widgetScript);

    (<any>window).jivo_onLoadCallback = () => this.onLoadChat();
    (<any>window).jivo_onClose = () => this.onCloseChat();

    this.jivoSiteService.setupUserData();
  }

  private getChatRootElement(): Element|null {
    return document.querySelector('body > jdiv');
  }

  private onChangeVisibility(visibility: boolean): void {
    if(visibility)
      this.showChat();
    else
      this.hideChat();
  }

  private showChat(): void {
    console.log('show jivo chat');
    this.getChatRootElement()?.classList.add('visible');

    if(!this.isVisible) {
      (<any>window).jivo_api?.open();
      this.isVisible = true;
    }
  }

  private hideChat(): void {
    console.log('hide jivo chat');
    this.getChatRootElement()?.classList.remove('visible');

    if(this.isVisible) {
      (<any>window).jivo_api?.close();
      this.isVisible = false;
    }
  }

  onLoadChat(): void {
    if(this.jivoSiteService.isVisible)
      this.showChat();
    else
      this.hideChat();
  }

  onCloseChat(): void {
    this.isVisible = false;
    this.jivoSiteService.hide();
  }

  ngOnDestroy(): void {
    this.visibilitySubscription?.unsubscribe();
    this.visibilitySubscription = undefined;
  }
}
