import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
  selector: 'additional-time-calculation',
  templateUrl: './additional-time.calculation.component.html'
})
export class AdditionalTimeCalculationComponent implements OnChanges {
  @Input() duration!: number;
  @Input() tarifficationPeriod: number = 1;
  @Input() tarifficationPeriodPrice!: number;
  @Input() multiplier = 1;
  @Input() cost?: number;

  periodsCount: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.recalculate();
  }

  private recalculate(): void {
    this.periodsCount = Math.ceil(this.duration / this.tarifficationPeriod);
  }
}
