import {Pipe, PipeTransform} from "@angular/core";
import {plural} from "ru-plurals";

const hoursPlural = plural('час', 'часа', 'часов');
const minutesPlural = plural('минута', 'минуты', 'минут');

@Pipe({name: "duration"})
export class DurationPipe implements PipeTransform {
  transform(minutes: number, abbreviation = true): string {
    let displayHours = Math.floor(minutes / 60);
    let displayMinutes = minutes - displayHours * 60;

    let components: string[] = [];
    if(displayHours > 0) {
      if(abbreviation)
        components.push(`${displayHours} ч`);
      else
        components.push(`${displayHours} ${hoursPlural(displayHours)}`);
    }

    if(displayMinutes > 0) {
      if(abbreviation)
        components.push( `${displayMinutes} мин.`);
      else
        components.push( `${displayMinutes} ${minutesPlural(displayMinutes)}`);
    }

    return components.join(' ');
  }
}
