import {Injectable} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {catchError, map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {EnterPromocodeResult} from "../_enums/enter-promocode-result";
import {PromoCode} from "../_models/promo-code";
import {PromoCodeType} from "../_enums/promo-code-type";

@Injectable()
export class PromoService {
  constructor(private _requestService: RequestWithErrorHandlerService, private alertService: AlertService) {
  }

  enter(code: string): Observable<EnterPromocodeResult> {
    return this._requestService
      .post('/me/promo.json', { code })
      .pipe(
        map(() => EnterPromocodeResult.Success),
        catchError(error => {
          if(error instanceof HttpErrorResponse) {
            switch(error.status) {
              case 404:
                this.alertService.clear();
                return of(EnterPromocodeResult.NotFound);
              case 405:
                this.alertService.clear();
                return of(EnterPromocodeResult.UsedOther);
              case 409:
                this.alertService.clear();
                return of(EnterPromocodeResult.Conflict);
              default:
                return throwError(error);
            }
          } else {
            return throwError(error);
          }
        })
      )
    ;
  }

  getEnteredPromocode(): Observable<PromoCode|null> {
    return this._requestService
      .get('/me/promo.json', { include: 'entered' })
      .pipe(
        map(r => {
          let promocodes = r.body.promo_codes as PromoCode[];
          for(const promocode of promocodes) {
            if(promocode.type === PromoCodeType.Entered)
              return promocode;
          }
          return null;
        })
      )
    ;
  }
}
