import {Injectable, NgZone} from "@angular/core";

declare var parentIFrame: any;

const MAX_TRIES = 5;
const TRY_DELAY = 2000;

@Injectable()
export class IFrameResizerService {
  private listeners = new Map<string, (info: any) => void>();
  private initTries = 0;
  private lastPageInfo: any|undefined;
  private _isIframed = false;

  constructor(private zone: NgZone) {
    this.initIframeResizer();
  }

  private initIframeResizer(): void {
    console.log(`Try to init iframe-resizer (try ${this.initTries + 1})...`);

    if('parentIFrame' in window) {
      console.log('Setup page info handler.');
      parentIFrame.getPageInfo((info: any) => this.onReceivedPageInfo(info));
      this._isIframed = true;
    } else if(this.initTries < MAX_TRIES) {
      console.log('Schedule next try.');
      this.initTries ++;
      setTimeout(() => this.initIframeResizer(), TRY_DELAY);
    } else {
      console.log('iframe-resizer not detected.');
    }
  }

  fixIframeSize(size?: number): void {
    if('parentIFrame' in window) {
      parentIFrame.autoResize(false);
      parentIFrame.size(size || 600);
    }
  }

  unfixIframeSize(): void {
    if('parentIFrame' in window) {
      parentIFrame.autoResize(true);
    }
  }

  addPageInfoListener(key: string, callback: (info: any) => void): void {
    this.listeners.set(key, callback);
    if(this.lastPageInfo)
      callback(this.lastPageInfo);
  }

  removePageInfoListener(key: string): void {
    this.listeners.delete(key);
  }

  refreshSize(): void {
    if('parentIFrame' in window) {
      parentIFrame.autoResize(false);
      parentIFrame.size();
      parentIFrame.autoResize(true);
    }
  }

  scrollTo(x: number, y: number): void {
    if('parentIFrame' in window) {
      parentIFrame.scrollTo(x, y);
    }
  }

  private onReceivedPageInfo(info: any): void {
    this.lastPageInfo = info;
    this.zone.run(() => this.listeners.forEach(callback => callback(info)));
  }

  get isIframed(): boolean {
    return this._isIframed;
  }
}
