import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {IndexComponent} from "./index/index.component";
import {CreateDraftComponent} from "./create-draft/create-draft.component";
import {CreateDraftSuccessComponent} from "./create-draft-success/create-draft-success.component";
import {TermsOfUseComponent} from "./terms-of-use/terms-of-use.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {TermsAndDefinitionsComponent} from "./terms-and-definitions/terms-and-definitions.component";
import {SupportComponent} from "./support/support.component";

const routes: Routes = [
  // { path: '', component: IndexComponent },
  { path: '', component: CreateDraftComponent },
  {
    path: 'draft',
    children: [
      { path: ':id/success', component: CreateDraftSuccessComponent },
      { path: '', component: CreateDraftComponent }
    ]
  },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-definitions', component: TermsAndDefinitionsComponent },
  { path: 'support', component: SupportComponent }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
