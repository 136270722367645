import {Destination} from "../_models/destination";
import {LiftType} from "../_enums/lift-type";
import {LIFT_TYPES_TO_DESTINATION_MAP} from "../_maps/lift-types";

export class LiftTypeUtils {
  static applyLiftTypeToDestination(destination: Destination, liftType: LiftType) {
    let liftParams = LIFT_TYPES_TO_DESTINATION_MAP[liftType];
    Object.assign(destination, liftParams);
  }

  static selectLiftTypeByDestination(destination: Destination): LiftType|undefined {
    const types = Object.values(LiftType);
    for(const type of types) {
      const liftParams = LIFT_TYPES_TO_DESTINATION_MAP[type];
      const liftParamsKeys = Object.keys(liftParams);
      let match = true;
      for(const liftParam of liftParamsKeys) {
        if((destination as any)[liftParam] !== liftParams[liftParam]) {
          match = false;
          break;
        }
      }
      if(match) {
        return type;
      }
    }
    return undefined;
  }
}
