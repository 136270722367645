import {Freighter} from "./freighter";
import {CompanyClient} from "./company-client";
import {BankCard} from "./bank-card";
import {Employer} from "./employer";
import {Auth} from "./auth";

export class Account {
  id?: number;
  name?: string;
  surname?: string;
  patronymic?: string;
  phone?: string;
  picture?: string;
  full_picture?: string;
  referral_promo?: string;
  picture_sizes?: any;
  sum?: number;
  email?: string;
  birthday?: string;
  gender?: string;
  city?: string;
  district?: string;
  vicinity?: string;
  subway_station?: string;
  voximplant?: string;
  freighter?: Freighter;
  company_client?: CompanyClient;
  main_company_client?: boolean;
  bank_cards?: BankCard[];
  employer?: Employer;
  is_test?: boolean;
  auth?: Auth;
}
