import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {IFrameResizerService} from "../_services/i-frame-resizer.service";

@Component({
  selector: 'app-create-draft-success',
  templateUrl: './create-draft-success.component.html',
  styleUrls: ['./create-draft-success.component.scss']
})
export class CreateDraftSuccessComponent implements OnInit {
  id: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private iframeResizerService: IFrameResizerService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        params => {
          this.id = params['id'];

          this.iframeResizerService.scrollTo(0, 0);
        },
        e => console.error(e)
      );
  }


}
