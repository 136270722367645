import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'round'})
export class RoundPipe implements PipeTransform {
  transform(value: any, type: string = 'ceil'): any {
    switch (type) {
      case 'ceil':
        return Math.ceil(value);
      case 'floor':
        return Math.floor(value);
      default:
        return Math.round(value);
    }
  }

}
