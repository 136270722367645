import {BankCard} from "../_models/bank-card";
import {DateTime} from "date-time-js";

export class BankCardUtils {
  static isCardExpired(card: BankCard): boolean {
    if(card.expire_date == null)
      return false;

    let expirationArray = card.expire_date.split('/').map(c => c.length == 1 ? '0' + c : c);
    if(expirationArray.length != 2)
      return false;

    let cardExpirationDate = new DateTime(`20${expirationArray[1]}-${expirationArray[0]}-01T00:00:00Z`);
    cardExpirationDate.add(1, 'month');

    return new DateTime().isGreaterOrEqual(cardExpirationDate);
  }
}
