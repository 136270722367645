import {Injectable} from "@angular/core";
import {Point} from "../_models/point";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {TariffTier} from "../_models/tariff-tier";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";

const AVAILABLE_TIERS = [ 'mini2', 'standart2', 'extra2' ];

@Injectable()
export class TariffService {
  constructor(private _requestService: RequestWithErrorHandlerService) { }

  getTier(point?: Point): Observable<any[]> {
    return this._requestService
      .get(`/tariff/tier.json`, {
        includeUnavailable: 'true',
        lat: point && point.lat,
        lon: point && point.lon
      })
      .pipe(
        map(r => r.body.tiers)
      )
    ;
  }

  getTaxiTiers(hidden?: boolean, withDescription = false): Observable<TariffTier[]> {
    return this._requestService
      .get(`/taxi/tiers.json`, {
        hide: hidden == null ? undefined : (hidden ? 1 : 0),
        withDescription: withDescription ? 1 : 0
      })
      .pipe(
        map(r => (r.body as TariffTier[]).filter(t => AVAILABLE_TIERS.indexOf(t.identifier) >= 0))
      )
    ;
  }
}
