import {Account} from "../_models/account";

export class UserNameUtils {
  static SNPToName(account: Account): string {
    return [ account.surname, account.name, account.patronymic ]
      .filter(s => s != null && s.trim() != '')
      .join(' ')
    ;
  }

  static nameToSNP(snp: string, account: Account): void {
    let name = '';
    let surname = '';
    let patronymic = '';

    let userName = snp
      .split(/\s+/).map(s => s.trim())
      .filter(s => s != '')
    ;

    if(userName.length == 3) {
      surname = userName[0];
      name = userName[1];
      patronymic = userName[2];
    } else if(userName.length == 2) {
      surname = userName[0];
      name = userName[1];
    } else if(userName.length == 1) {
      name = userName[0];
    } else if(userName.length > 3) {
      surname = userName[0];
      name = userName[1];
      patronymic = userName.slice(2).join(' ');
    }

    account.name = name;
    account.surname = surname;
    account.patronymic = patronymic;
  }

  static getAccountFullName(account: Account, defaultName: string = ''): string {
    let name = [ account.surname, account.name, account.patronymic ];

    let strName = name.filter(name => name != null).join(' ').trim();
    return strName === '' ? defaultName : strName;
  }
}
