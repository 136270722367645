import { Component, OnInit } from '@angular/core';
import {InitOrder} from "../_models/init-order";
import {DestinationPoint} from "../_models/destination-point";
import {TariffService} from "../_services/tariff.service";
import {Point} from "../_models/point";
import {TariffTier} from "../_models/tariff-tier";
import {OrderDraftService} from "../_services/order-draft.service";
import {OrderDraft} from "../_models/order-draft";
import {Account} from "../_models/account";
import {ExtraSearchParams} from "../_models/extra-search-params";
import {Destination} from "../_models/destination";
import {DraftStorageService} from "../_services/draft-storage.service";
import {DraftSpecial} from "../_models/draft-special";
import {TierDescriptor} from "../_models/tier-descriptor";
import {TIER_DESCRIPTORS} from "../_maps/tier-descriptors";

@Component({
  selector: 'init-order-block',
  templateUrl: './init-order-block.component.html',
  styleUrls: ['./init-order-block.component.scss']
})
export class InitOrderBlockComponent implements OnInit {
  initOrder = new InitOrder();

  tier: any[] = [];
  tiers: TariffTier[] = [];
  tierDescriptors: TierDescriptor[] = [];
  tierCalculations: any = {};

  private draftSpecial = new DraftSpecial('obi', 'first');

  constructor(
    private tariffService: TariffService,
    private draftService: OrderDraftService,
    private draftStorageService: DraftStorageService
  ) {
    this.initOrder.points = [
      new DestinationPoint(),
      new DestinationPoint(),
    ];

    let firstPoint = this.initOrder.points[0];
    firstPoint.lat = 55.72665;
    firstPoint.lon = 37.455598;
    firstPoint.addr = '';
  }

  ngOnInit(): void {
    this.loadTiers();
  }

  private loadTiers(): void {
    this.tariffService
      .getTaxiTiers(false)
      .subscribe(
        tiers => {
          this.tiers = tiers;
          this.remapTierDescriptions();
          if(tiers.length > 0) {
            this.initOrder.tierIdentifier = tiers[0].identifier;
            this.reloadTier();
            this.recalculateTiers();
          }
        }
      )
    ;
  }

  private remapTierDescriptions(): void {
    this.tierDescriptors = [];
    for(let tier of this.tiers) {
      let descriptor = TIER_DESCRIPTORS[tier.identifier];
      if(descriptor)
        this.tierDescriptors.push(descriptor);
    }
  }

  private loadTier(point: Point): void {
    this.tariffService
      .getTier(point)
      .subscribe(
        t => this.tier = t
      );
  }

  private reloadTier(): void {
    let points = this.getAllValidPoints();
    if(points.length == 0)
      return;

    let point = new Point();
    point.lat = points[0].lat;
    point.lon = points[0].lon;

    this.loadTier(point);
  }

  hasAnyValidPoints(): boolean {
    return this.getAllValidPoints().length > 0;
  }

  private isAllPointsValid(): boolean {
    return this.getAllValidPoints().length == this.initOrder.points.length;
  }

  private recalculateTiers(): void {
    if(!this.hasAnyValidPoints())
      return;

    this.tiers.map(t => this.buildDraftForCalculations(t)).forEach(d => this.calculateDraft(d));
  }

  private calculateDraft(draft: OrderDraft): void {
    this.draftService
      .requestCalculation(draft, this.draftSpecial)
      .subscribe(
        calculation => {
          let tierIdentifier = draft.extra_search_params!.tariff_tier!.identifier;
          this.tierCalculations[tierIdentifier] = calculation;
        },
        () => {}
      );
  }

  private getAllValidPoints(): DestinationPoint[] {
    return this.initOrder.points.filter(p => p.lat && p.lon);
  }

  private buildDraftForCalculations(tier: TariffTier): OrderDraft {
    let draft = new OrderDraft();

    draft.client = new Account();

    draft.extra_search_params = new ExtraSearchParams();
    draft.extra_search_params.tariff_tier = tier;
    draft.destinations = this.getAllValidPoints().map(point => {
      let destination = new Destination();
      destination.destination = point;

      return destination;
    });

    return draft;
  }

  getCalculation(tier: TariffTier): any|undefined {
    return this.tierCalculations[tier.identifier];
  }

  getCalculationByTierIdentifier(identifier: string): any|undefined {
    return this.tierCalculations[identifier];
  }

  getCreateDraftParams(): any {
    let params: any = {
      tier: this.initOrder.tierIdentifier
    };

    let points = this.getAllValidPoints();
    for(let i = 0; i < points.length; i++) {
      params[`point[${i}][addr]`] = points[i].addr;
      params[`point[${i}][lat]`] = points[i].lat;
      params[`point[${i}][lon]`] = points[i].lon;
    }

    return params;
  }

  getTierData(tier: TariffTier): any|undefined {
    for(let tierData of this.tier) {
      if(tierData.identifier === tier.identifier)
        return tierData;
    }
  }

  onChangeAddress(): void {
    this.recalculateTiers();
    this.reloadTier();
    this.draftStorageService.removeDraftFromStorage();
  }

  onSelectTier(tierDescriptor: TierDescriptor): void {
    this.initOrder.tierIdentifier = tierDescriptor.identifier;
  }
}
