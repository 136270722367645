import {DestinationPoint} from "./destination-point";
import {DeliveryProduct} from "./delivery-product";
import {LegalEntity} from "./legal-entity";

export class Destination {
  id?: number;
  type?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_send_sms?: boolean;
  destination?: DestinationPoint;
  elevator?: boolean;
  floor?: number;
  lifting?: boolean;
  loading?: boolean;
  unloading?: boolean;
  unloading_internet_shop_order?: boolean;
  required_additional_time?: boolean;
  delivery_to?: string;
  comment?: string;
  arrival_time?: string;
  delivery_product?: DeliveryProduct;
  time_slot_begin?: string;
  time_slot_end?: string;
  order_code?: string;
  client_legal_entity?: LegalEntity;
}
