<!--<div class="header">-->
<!--  <div class="header_content">-->
<!--    <div class="header_menu_left">-->
<!--      <div class="header_menu_box">-->
<!--        <div class="header_menu_btn">Для бизнеса</div>-->
<!--        <div class="header_menu_btn">Магазины</div>-->
<!--        <div class="header_menu_btn">Помощь</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="header_menu_right">-->
<!--      <div class="header_menu_right_icon application_icon1"></div>-->
<!--      <div class="header_menu_right_icon application_icon2"></div>-->
<!--      <div class="header_menu_right_icon application_icon3"></div>-->
<!--      <div class="header_menu_right_icon application_icon4"></div>-->
<!--      <div class="header_menu_right_text">Мои объявления</div>-->
<!--      <div class="header_menu_right_photo"></div>-->
<!--      <div class="header_menu_right_name">Александр</div>-->
<!--      <div class="header_menu_right_arrow"></div>-->
<!--      <div class="header_menu_right_btn">Разместить объявление</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="bottom_header">-->
<!--  <div class="bottom_header_content">-->
<!--    <div class="bottom_header_content_mobil"></div>-->
<!--    <div class="logo_box_bottom_header"></div>-->
<!--    <div class="menu_box_bottom_header">-->
<!--      <div class="item_mbbh">Авто</div>-->
<!--      <div class="item_mbbh">Недвижимость</div>-->
<!--      <div class="item_mbbh">Работа</div>-->
<!--      <div class="item_mbbh">Услуги</div>-->
<!--      <div class="item_mbbh">Ещё…</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="success">
  <div class="success_content">
    <div class="left_success_content">
      <div class="left_success_content_title">Заказ №{{ id }} в работе!</div>
      <div class="left_success_content_title_mobil">Заказ №{{ id }} в работе!</div>
      <div class="left_success_content_mini_title_mobil">
        Мы уже ищем грузоперевозчика — обычно это занимает не больше 10 минут. Водитель позвонит перед выездом.
        <br><br>
        Когда заказ будет выполнен, вам придёт смс с его стоимостью. Если выбрали оплату наличными, отдайте их водителю.
        Оплатить заказ картой нужно по ссылке из смс.
      </div>
      <div class="left_success_content_text">
        Мы уже ищем грузоперевозчика — обычно это занимает не больше 10 минут. Водитель позвонит перед выездом.
        <br><br>
        Когда заказ будет выполнен, вам придёт смс с его стоимостью. Если выбрали оплату наличными, отдайте их водителю.
        Оплатить заказ картой нужно по ссылке из смс.
      </div>
      <div class="left_success_content_btn" [routerLink]="[ '/' ]">Все понятно!</div>
    </div>
    <div class="right_success_content">
      <div class="right_success_content_photo"></div>
    </div>
  </div>
</div>
<!--<div class="footer_mini">-->
<!--  <div class="footer_mini_content">-->
<!--    <div class="top_mini_footer">-->
<!--      <div class="top_mini_footer_menu">Разместить объявление</div>-->
<!--      <div class="top_mini_footer_menu">Объявления</div>-->
<!--      <div class="top_mini_footer_menu">Магазины</div>-->
<!--      <div class="top_mini_footer_menu">Помощь</div>-->
<!--      <div class="top_mini_footer_menu">Безопасность</div>-->
<!--      <div class="top_mini_footer_menu">Реклама на сайте</div>-->
<!--      <div class="top_mini_footer_menu">О компании</div>-->
<!--      <div class="top_mini_footer_menu">Карьера</div>-->
<!--      <div class="top_mini_footer_menu_bold">Мобильное приложение</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="bottom_mini_footer">-->
<!--  <div class="bottom_mini_footer_content">-->
<!--    <div class="bottom_mini_footer_item">Авито — сайт объявлений. © ООО «КЕХ еКоммерц» 2007–2019.</div>-->
<!--    <a href="" class="bottom_mini_footer_href">Условия использования Авито.</a>-->
<!--    <a href="" class="bottom_mini_footer_href">Политика о данных пользователей.</a>-->
<!--    <div class="bottom_mini_footer_item">Оплачивая услуги на Авито, вы принимаете</div>-->
<!--    <a href="" class="bottom_mini_footer_href">оферту.</a>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="mobil_footer">-->
<!--  <div class="mobil_footer_mini_content">-->
<!--    <div class="mobil_top_mini_footer">-->
<!--      <div class="mobil_top_mini_footer_menu">Помощь</div>-->
<!--      <div class="mobil_top_mini_footer_menu">О компании</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Вакансии</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Безопасность</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Реклама на сайте</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Оферта</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Политика о данных пользователей</div>-->
<!--      <div class="mobil_top_mini_footer_menu">Условия использования авито</div>-->
<!--    </div>-->
<!--    <div class="mobil_top_mini_footer_copir">© ООО «КЕХ еКоммерц» 2007-2021</div>-->
<!--    <div class="mobil_bottom_mini_footer">-->
<!--      <div class="item_mobil_bottom_mini_footer">Мобильное приложение</div>-->
<!--      <div class="item_mobil_bottom_mini_footer">Полная версия</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
