import {Injectable} from "@angular/core";
import {AlertService} from "./alert.service";
import {Observable, of, throwError} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {catchError} from "rxjs/operators";

@Injectable()
export class HttpErrorHandlerService {
  constructor(private _alertService: AlertService) {}

  addHandler(request: Observable<HttpResponse<any>>): Observable<HttpResponse<any>> {
    return request.pipe(catchError((error: any, caught: Observable<HttpResponse<any>>) => {
      console.error(error);

      switch (error.status) {
        case 500:
          this._alertService.error('Ошибка на сервере');
          break;
        case 400:
          this._alertService.warning('Некорректные данные');
          break;
        case 401:
          this._alertService.error('Требуется повторная авторизация');
          break;
        case 403:
          this._alertService.error('Нет доступа');
          break;
        case 404:
          this._alertService.error('Ресурс не найден');
          break;
        case 405:
          this._alertService.warning('Действие недопустимо');
          break;
      }

      return throwError(error);
    }));
  }

  get alertService(): AlertService {
    return this._alertService;
  }

  clear() {
    this._alertService.clear();
  }
}
