import {Injectable} from "@angular/core";
import {City} from "../_models/city";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {from, Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {SessionStorageService} from "./session-storage.service";

const ACTIVE_CITY_STORAGE = 'city';

@Injectable()
export class CityService {
  private citiesPromise?: Promise<City[]>;
  private _isActiveCityLoaded = false;
  private _activeCity?: City;
  private changeCityStream = new Subject<City>();

  constructor(private _requestService: RequestWithErrorHandlerService, private sessionStorageService: SessionStorageService) {
    // this.initActiveCity();
  }

  private initActiveCity(): void {
    let storedActiveCity: number = parseInt(this.sessionStorageService.getItem(ACTIVE_CITY_STORAGE) || '0');
    if(!storedActiveCity) {
      this._isActiveCityLoaded = true;
      return;
    }

    this.getCities()
      .subscribe(
        cities => {
          this._activeCity = cities.find(city => city.id == storedActiveCity);
          this._isActiveCityLoaded = true;
          this.changeCityStream.next(this._activeCity);
        },
        () => {}
      );
  }

  get activeCity(): City | undefined {
    return this._activeCity;
  }

  set activeCity(value: City | undefined) {
    this._activeCity = value;

    if(value) {
      this.sessionStorageService.setItem(ACTIVE_CITY_STORAGE, value.id + '');
    } else {
      this.sessionStorageService.removeItem(ACTIVE_CITY_STORAGE);
    }

    this.changeCityStream.next(this._activeCity);
  }

  getCities(refresh = false): Observable<City[]> {
    if(refresh)
      this.citiesPromise = undefined;

    if(this.citiesPromise == null) {
      this.citiesPromise = this._requestService
        .get('/cities.json')
        .pipe(map(r => r.body as City[]))
        .toPromise()
      ;
    }

    return from(this.citiesPromise);
  }

  createChangeActiveCityObservable(): Observable<City> {
    return this.changeCityStream.asObservable();
  }

  get isActiveCityLoaded(): boolean {
    return this._isActiveCityLoaded;
  }
}
