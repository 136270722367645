import {Injectable} from "@angular/core";
import {RequestWithErrorHandlerService} from "./request-with-error-handler.service";
import {Account} from "../_models/account";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class MeService {
  constructor(private _requestService: RequestWithErrorHandlerService) {}

  updateMyName(account: Account): Observable<HttpResponse<any>> {
    return this._requestService
      .put('/me/name.json', {
        name: account.name,
        surname: account.surname,
        patronymic: account.patronymic
      });
  }
}
