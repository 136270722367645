import {RequestService} from "./request.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {Point} from "../_models/point";
import {map} from "rxjs/operators";
import {Address} from "../_models/address";

@Injectable()
export class GeoService {
  constructor(private requestService: RequestService) {}

  getAddresses(search: string, context?: string): Observable<Address[]> {
    return this.requestService
      .get('/geo/address.json', { address: search, context: context || null })
      .pipe(map(r => r.body as Address[]))
    ;
  }

  getLocation(lat: number, lon: number, context?: string): Observable<Address> {
    return this.requestService
      .get('/geo/location.json', { location: `${lat},${lon}`, context })
      .pipe(map(r => r.body as Address))
  }

  getGooglePlace(placeId: string, context?: string): Observable<Address> {
    return this.requestService
      .get('/geo/location/google.json', { placeId, context })
      .pipe(map(r => r.body as Address))
  }

  getRoute(points: Point[], zoom: number = 11): Observable<Point[]> {
    let data = GeoService.prepareLocationsForRequest(points);
    data['zoom'] = zoom;

    return this.requestService
      .get('/geo/route.json', data)
      .pipe(map(r => r.body.route_geometry_points as Point[]))
      ;
  }

  private static prepareLocationsForRequest(points: Point[]): any {
    let result: any = {};

    for(let i = 0; i < points.length; i ++) {
      let point = points[i];
      let key = `location[${i}]`;

      result[key] = `${point.lat},${point.lon}`;
    }

    return result;
  }
}
