import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IndexComponent} from './index/index.component';
import {RequestService} from "./_services/request.service";
import {InitOrderBlockComponent} from './init-order-block/init-order-block.component';
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {AddressFieldComponent} from './address-field/address-field.component';
import {GeoService} from "./_services/geo.service";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {TariffService} from "./_services/tariff.service";
import {AlertComponent} from "./alert/alert.component";
import {AlertService} from "./_services/alert.service";
import {HttpErrorHandlerService} from "./_services/http-error-handler.service";
import {RequestWithErrorHandlerService} from "./_services/request-with-error-handler.service";
import {OrderDraftService} from "./_services/order-draft.service";
import {CityService} from "./_services/city.service";
import { CreateDraftComponent } from './create-draft/create-draft.component';
import {RoundPipe} from "./_pipes/round.pipe";
import {UserInfoService} from "./_services/user-info.service";
import {LoginService} from "./_services/login.service";
import {RegisterAccountService} from "./_services/register-account.service";
import {FormatPhonePipe} from "./_pipes/format-phone.pipe";
import {LogoutService} from "./_services/logout.service";
import {BankCardService} from "./_services/bank-card.service";
import {DraftStorageService} from "./_services/draft-storage.service";
import {MeService} from "./_services/me.service";
import * as moment from "moment";
import {MapToIterablePipe} from "./_pipes/map-to-iterable.pipe";
import {AdditionalTimeCalculationComponent} from "./create-draft/additional-time.calculation.component";
import { CreateDraftSuccessComponent } from './create-draft-success/create-draft-success.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {IFrameResizerService} from "./_services/i-frame-resizer.service";
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndDefinitionsComponent } from './terms-and-definitions/terms-and-definitions.component';
import {DurationPipe} from "./_pipes/duration.pipe";
import {registerLocaleData} from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import {TokenService} from "./_services/token.service";
import {LocalStorageService} from "./_services/local-storage.service";
import {SessionStorageService} from "./_services/session-storage.service";
import {JivoSiteService} from "./_services/jivo-site.service";
import {JivoSiteComponent} from "./jivo-site/jivo-site.component";
import { SupportComponent } from './support/support.component';
import {PromoService} from "./_services/promo.service";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";

registerLocaleData(localeRu)

export function initApp(userInfoService: UserInfoService) {
  return () => userInfoService
    .setup()
    .catch(e => {
      console.log(e);
      return e;
    });
}

@NgModule({
  declarations: [
    // pipes
    RoundPipe,
    FormatPhonePipe,
    MapToIterablePipe,
    DurationPipe,

    AppComponent,
    IndexComponent,
    InitOrderBlockComponent,
    AddressFieldComponent,
    AlertComponent,
    CreateDraftComponent,
    AdditionalTimeCalculationComponent,
    CreateDraftSuccessComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    TermsAndDefinitionsComponent,
    JivoSiteComponent,
    SupportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AutocompleteLibModule,
    HttpClientModule,
    FormsModule,
    NgxMaskDirective
  ],
  providers: [
    UserInfoService,
    RequestService,
    GeoService,
    TariffService,
    AlertService,
    HttpErrorHandlerService,
    RequestWithErrorHandlerService,
    OrderDraftService,
    CityService,
    BankCardService,
    LoginService,
    LogoutService,
    RegisterAccountService,
    DraftStorageService,
    MeService,
    IFrameResizerService,
    TokenService,
    LocalStorageService,
    SessionStorageService,
    JivoSiteService,
    PromoService,
    provideNgxMask(),
    {
      'provide': APP_INITIALIZER,
      'useFactory': initApp,
      'deps': [UserInfoService],
      'multi': true
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    moment.locale('ru_RU');
  }
}
