<div class="terms-of-use">
  <h4>Пользовательское соглашение</h4>

  <p>Настоящее Пользовательское соглашение (далее — «Соглашение») представляет собой договор между Пользователем с одной
    и компанией ООО «8РХ», в лице Генерального Директора Мегеряна Амаяка Людвиговича, действующего на основании Устава с
    другой стороны, зарегистрированного по законодательству РФ (далее — «Mover», «Администрация»).</p>
  <p>Mover предоставляет онлайн и мобильную платформу (далее «Сервис») для взаимодействия Грузоотправителей с
    Грузоперевозчиками, в результате чего Грузоотправители могут подавать заявку на аренду транспортного средства с
    экипажем, а Грузоперевозчики могут отслеживать и принимать такие запросы. </p>
  <p>Внимательно ознакомьтесь с условиями настоящего Пользовательского соглашения перед началом работы с Сервисом.
    Получая доступ или используя любую часть Сервиса, Пользователь соглашается с условиями данного Соглашения. Если
    Пользователь не согласен со всеми условиями данного Соглашения, он не можете использовать Сервис и его услуги.</p>


  <h4>1. Определения</h4>

  <p>Стороны признают, что все термины и понятия, используемые в настоящем Соглашении, любые приложения и дополнения к
    нему, для целей надлежащего исполнения обязанностей, если иное прямо не предусмотрено, толкуются в соответствии с
    нижеприведенными определениями:</p>

  <p>1.1. «Разработчик»</p>
  <p>ООО «8РХ» является разработчиком мобильного приложения и Сервиса www.mover24.ru, единственная обязанность которого
    - организовать коммуникацию посредством Сервиса между Заказчиком и Перевозчиком грузов.</p>
  <p>Как разработчик, Mover не владеет, не распоряжается и не контролирует любой груз. Mover не несет финансовую
    ответственность за возможные убытки в случае потери или порчи груза.</p>
  <p>1.2. «Пользователь»</p>
  <p>Пользователь - любое физическое или юридическое лицо, дееспособное физическое лицо, достигшее возраста,
    необходимого в соответствии с законодательством Российской Федерации для совершения указанных в настоящем Соглашении
    действий, использующее Сервис; Перевозчик или Грузоотправитель.</p>
  <p>1.3. «Грузоотправитель»</p>
  <p>Грузоотправитель - владелец, арендатор или уполномоченное лицо, имеющее право распоряжаться имуществом/грузом.
    Mover не является агентом для Грузоотправителя.</p>
  <p>1.4. «Поставщик услуг» или «Грузоперевозчик»</p>
  <p>юридическое лицо или индивидуальный предприниматель, которое(-ый) владеет парком грузовых автомобилей, организует
    водителей для непосредственного выполнения, либо индивидуальный предприниматель или физическое лицо – водитель,
    лично исполняющий заказы. Термин включает в себя водителей перевозчика, независимо от того являются ли они
    сотрудниками или независимыми подрядчиками. Перевозчик несет полную ответственность за техническое обслуживание
    транспортных средств, соблюдение норм и правил безопасности, водителей-сотрудников и подрядчиков. Грузоперевозчик
    сохраняет единоличный контроль над методами, с помощью которых он выполняет грузовые транспортные услуги, сохраняет
    единоличную обязанность управлять и контролировать персонал.</p>
  <p>1.5. «Контент»</p>
  <p>Контент - любая информация, которая позволяет отправить груз или использовать Сервис.</p>
  <p>1.6. «Приложение» </p>
  <p>Приложение - комплекс программ, связанных через сеть Интернет, и баз данных для мобильного устройства,
    обеспечивающих возможность обработки информации и команд Пользователя, предназначенных для работы на смартфонах,
    планшетных компьютерах и/или других устройствах, с целью выбора услуг Поставщиков услуг, формирования и оплаты
    Заказа.</p>
  <p>1.7. «Сайт»</p>
  <p>Интернет портал, расположенный по адресу www.mover24.ru, представляющий собой интерфейс программирования. Сайт
    является собственностью и управляется компанией ООО “8PX”.</p>
  <p>1.8. «Заказ»</p>
  <p>Должным образом оформленный с помощью Сервиса запрос Пользователя, содержащий существенные условия договора аренды
    транспортного средства с экипажем для целей перевозки груза, и объединенный единым идентификационным номером.</p>
  <p>1.9. «Возвратный платеж»</p>
  <p>Любые суммы, возвращаемые Пользователю вследствие отказа от Услуги или в результате оспаривания операций по
    кредитной карте, а также любые связанные с этим сборы или штрафы.</p>
  <p>1.10. «Администрация»</p>
  <p>Лица, уполномоченные компанией ООО “8PX” на управление работой Сервиса, контролирующие исполнение Пользователями
    обязательств по настоящему Соглашению.</p>
  <p>1.11. «Личный кабинет»</p>
  <p>Индивидуальная страница Пользователя в Сервисе, доступная после ввода логина и пароля, интерфейс которой содержит
    персонифицированные информационные данные и статистику заказов.</p>
  <p>1.12. «Логин и Пароль»</p>
  <p>Уникальные наборы символов, указанные при регистрации, сочетание которых позволяет Администрации идентифицировать
    Пользователя.</p>
  <p>1.13. «Транспортное средство» </p>
  <p>Транспортное средство с необходимой грузоподъемностью, которое соответствует требованиям IRU (Международного Союза
    Автоперевозчиков), либо техническим нормам и нормам безопасности Российской Федерации. </p>
  <p>1.14. «Реферальный Промокод» </p>
  <p>Набор буквенно-численных значений передаваемых Пользователями Сервиса другим Пользователям Сервиса или иным лицам с
    целью получения скидки на перевозку </p>

  <h4>2. Предмет договора</h4>

  <p>2.1. Администрация предоставляет Пользователю или представителю Пользователям на основании документов,
    подтверждающих полномочия, на условиях простой неисключительной лицензии доступ к Программному обеспечению Mover
    посредством Мобильного приложения и Cайта www.mover24.ru.</p>
  <p>2.2. В соответствии с условиями настоящего Соглашения Администрация предоставляет Пользователю право использования
    Программы как на территории Российской Федерации, так и на территории других государств.</p>
  <p>2.3. Основные функции Программы включают в себя:</p>
  <p>2.3.1. предоставление информационного обмена между Грузоотправителями и Поставщиками услуг;</p>
  <p>2.3.2. право использовать информационную базу для поиска, формирования заказа и заключения договора аренды
    транспортного средства с экипажем с Грузоперевозчиком.  </p>
  <p>2.3.3. возможность отслеживать в режиме реального времени, приблизительное местонахождение Поставщика услуг
    относительно места погрузки.</p>
  <p>2.4. Формирование Заказа осуществляется путем предоставления доступа к информации о предложениях Поставщиков услуг
    и услугах по перевозке, о транспортных средствах, а также информации о категории и грузоподъемности, оказываемых за
    общую цену по договору аренды транспортного средства с экипажем между Пользователем и Поставщиком услуг. </p>
  <p>2.5. Настоящим Пользователь признает и соглашается с тем, что он способен оценить обстоятельства Сделок,
    заключаемых в рамках настоящего Соглашения, и подтверждает самостоятельность в принятии решения о заключении Сделок
    и понимает связанные с ними риски.</p>
  <p>2.6. Пользователь также вправе использовать Сервис иными способами, не противоречащими настоящему Соглашению. Все
    существующие на данный момент функции Сервиса, а также любое развитие их и/или добавление новых является предметом
    настоящих Условий.</p>
  <p>2.7. Разработчик не является Поставщиком услуг. Все обязательства по аренде транспортного средства с экипажем
    возникают между Перевозчиком и Грузоотправителем. </p>
  <p>2.8. Пользователь обязуется оплачивать оказываемые услуги Грузоперевозчиков в порядке и на условиях, указанных в
    настоящем Соглашении.</p>


  <h4>3. Ваш MOVER аккаунт</h4>

  <p>3.1.1. Для доступа ко всем функциям Сервиса, Пользователю необходимо пройти регистрацию и предоставить определенную
    информацию, следуя указаниям в форме регистрации. </p>
  <p>3.1.2. В случае регистрации на Сайте Пользователь несет ответственность за обеспечение   безопасности Личного
    Кабинета и за все действия, совершаемые под учетной записью.</p>
  <p>3.1.3. В случае несанкционированного доступа к вашей учетной записи или нарушения безопасности Пользователь
    обязуется сообщить об этом Администрации.</p>
  <p>3.2. Администрация не несет ответственность за любые действия или бездействие, в том числе убытки любого рода,
    понесенные в результате несанкционированного доступа. </p>
  <p>3.3. Администрация оставляет за собой право, но не берет на себя обязательство, приостанавливать или аннулировать
    доступ к Сайту www.mover24.ru и сервисам MOVER, если</p>
  <ul>
    <li>a. пользователь нарушает любое из условий настоящего Соглашения или других письменных правил и процедур,
      размещенных на Сайте,
    </li>
    <li>b. Администрация не в состоянии проверить или подтвердить информацию, которую Пользователь предоставляет, или
    </li>
    <li>c. Администрация полагает, что действия Пользователя могут повлечь юридическую ответственность в отношении
      клиентов ООО “8PX” или компании.
    </li>
  </ul>
  <p>3.4. После приостановления или удаления учетной записи Пользователь НЕ МОЖЕТ продолжать использовать Сайт
    www.mover24.ru и сервисы MOVER под другой учетной записью или повторно регистрироваться под новой учетной
    записью.</p>
  <p>3.5. После аннулирования учетной записи Пользователь утрачивает доступ к любым элементам Сервиса Mover, включая
    данные, сообщения, файлы и другие материалы, которые хранятся на ресурсе.</p>

  <h4>4. Формирование заказа аренды транспортного средства с экипажем</h4>

  <p>4.1. Заказ на аренду транспортного средства с экипажем (фрахтование) для целей перевозки груза.</p>
  <p>4.1.1. Заказ на аренду транспортного средства с экипажем формируется в Приложении и на веб-сайте Mover и является
    публичной офертой. При внесении изменений в Заказ после ее формирования, информация об этом немедленно размещается в
    Сервисе и несет функцию публичной оферты. </p>
  <p>4.1.2. Грузоотправитель должен предоставить следующую информацию необходимую для оформления запроса:</p>
  <ul>
    <li>a. Адрес нахождения и доставки груза;</li>
    <li>b. Дату перевозки;</li>
    <li>c. Описание перевозимого груза (включая вес) и его упаковки;</li>
    <li>d. Требования к транспортному средству;</li>
    <li>e. Количество необходимых транспортных средств;</li>
  </ul>
  <p>а также:</p>
  <ul>
    <li>f. Согласовать погрузочно-разгрузочные услуги;</li>
    <li>g. Выбрать способ оплаты аренды транспортного средства с экипажем;</li>
    <li>k. Предоставить платежные реквизиты.</li>
  </ul>
  <p>4.1.3. Перевозчик вправе просматривать список Заказов в любой момент и принять заказ с помощью Сервиса. Mover не
    несет ответственности за любые правоотношения, возникшие между Грузоотправителем и Перевозчиком. </p>
  <p>4.1.4. Грузоотправитель может отменить отправление в любое время до его принятия Грузоперевозчиком. </p>
  <p>4.1.5. После принятия Грузоперевозчиком заказа, Mover уведомит Грузоотправителя. Mover не гарантирует, что Заказ
    будет исполнен Грузоперевозчиком.</p>
  <p>4.2. Описание груза и раскрытие информации</p>
  <p>Грузоотправитель должен предоставить всю информацию, разумно необходимую, для оказания транспортных услуг в
    соответствии с действующими нормами, законами и отраслевыми стандартами, сообщить необходимое время аренды
    транспортного средства. Грузоотправитель несет ответственность за предоставление точного описания груза, включая без
    ограничения тип товаров, размер и вес, и специальные требования перевозки и упаковки.</p>
  <p>4.3. Упаковка и транспортировка</p>
  <p>4.3.1. Отправитель несет полную ответственность за выбор или отказ от транспортного средства Грузоперевозчика из-за
    условий, которые могут оказаться неприемлемыми для Грузоотправителя: чистота, запах, герметичность, влажность или
    прочих условиях перевозки.</p>
  <p>4.3.2. Грузоотправитель несет ответственность за упаковку, которая обеспечивает безопасную укладку груза и
    предотвращает повреждение во время транспортировки.</p>
  <p>4.3.3. Если транспортное средство является неприемлемым, Грузоотправитель должен уведомить Mover немедленно и
    запросить альтернативное транспортное средство.</p>
  <p>4.4. Ответственность за транспортировку</p>
  <p>4.4.1. Грузоперевозчик действует в качестве независимого подрядчика и не уполномочен представлять ООО “8PX”.</p>
  <p>4.4.2. Если иное не предусмотрено в настоящем Соглашении, ответственность за утрату или повреждение груза несет
    Грузоперевозчик.</p>
  <p>4.4.3. Грузоперевозчик несет ответственность перед Mover и его клиентами за любой ущерб собственности, потери
    груза, повреждения или задержки, произошедших во время аренды транспортного средства с экипажем, или в результате
    исполнения или неисполнения обязательств сотрудниками Грузоперевозчика.</p>
  <p>4.4.4. Претензии, связанные с потерей или повреждением груза должны быть представлены Грузоперевозчику. </p>
  <p>4.5. Завершение аренды транспортного средства с экипажем.</p>
  <p>После окончания срока аренды транспортного средства с экипажем Грузоперевозчик обязуется загрузить в Сервис: (1)
    Счет-фактуру и товарную накладную; или (2) Акт выполненных работ от уполномоченного получателя. </p>
  <p>4.6. Администрация Mover не дает рекомендаций или консультаций по существу или целесообразности заключения договора
    с конкретным Поставщиком услуг. Разъяснение условий Сделки или общие пояснения по структуре Функционирования Сервиса
    не являются консультацией по существу договора аренды транспортного средства с экипажем.</p>
  <p>4.7. По окончания срока аренды транспортного средства с экипажем получатель обязан осмотреть груз и убедиться в
    комплектности и отсутствии повреждений. В случае наличия претензий к работе Грузоперевозчика получатель обязан
    оформить их в письменной форме в момент получения груза и передать их сотрудникам Грузоперевозчика, а также
    проинформировать сервис Mover. В случае отсутствия претензий получателя при приёме-сдаче аренда транспортного
    средства с экипажем считается оконченной без замечаний, а груз доставленным без утраты и повреждений.</p>

  <h4>5. Оплата аренды транспортного средства с экипажем.</h4>

  <p>5.1. Грузоотправитель обязуется оплачивать услуги Поставщика (Грузоперевозчика), а также любые дополнительные
    расходы, связанные с арендой транспортного средства с экипажем. Расходы включают в себя простой свыше 30 минут в
    месте погрузки/разгрузки, вызванный за счет Грузоотправителя, но не меньше минимального тарифа. Mover может изменить
    ценообразование услуг (в любое время по своему усмотрению), обновляя сайт и приложение для мобильных телефонов без
    каких-либо дополнительных уведомления пользователей.</p>
  <p>5.2. Все цены указаны за весь объем услуг Грузоперевозчика, включая НДС и другие налоги в соответствии с
    законодательством РФ, если не указано иное.</p>
  <p>5.3. Для оплаты услуг Грузоперевозчика Пользователь выбирает следующие способы: наличными, банковской картой, либо
    на основании счета выставленного на юр. Лицо, либо на представителя юр. Лица. Пользователь производит оплату в
    соответствии с правилами осуществления платежей выбранного Пользователем способа оплаты. </p>
  <p>5.3.1. Оплата услуг наличными осуществляется непосредственно Грузоперевозчику в момент оказания услуг,</p>
  <p>5.3.2. Mover принимает интернет-платежи за Услуги по следующим кредитным картам: Visa, MasterCard, МИР и
    осуществляет перевод денежных средств через интернет-эквайринг Альфа-Банка. Пользователь соглашается, что
    подтверждение Заказа, оплаченного банковской картой, происходит только после подтверждения списания денежных средств
    в счёт оплаты Заказа. </p>
  <p>5.4. Mover доводит до сведения Пользователя, что безопасность обработки интернет-платежей гарантируется нашим
    провайдером Альфа-Банком (АО "АЛЬФА-БАНК"). Mover не несет ответственности за безопасность производимого
    Пользователем платежа, перевода с использованием услуг сторонней организации, а также не отвечает по обязательствам
    данной организации. Пользователь предупрежден, что, вступая в отношения по оформлению любых платежей через стороннюю
    организацию, Администратор снимает с себя ответственность за любые недостатки в работе организации ее служб. При
    этом Mover и Пользователь в качестве презумпции признают, что операции по банковским картам совершаются держателем
    карты либо лицом, уполномоченным на основании доверенности, оформленной в соответствии с законодательством РФ.</p>
  <p>Подробнее о безопасности обработки онлайн платежей: <a href="http://mover24.ru/payments.html" target="_blank">http://mover24.ru/payments.html</a>
  </p>
  <p>5.5. Настоящим Пользователь соглашается, что выбранный способ оплаты не подлежит изменению с момента оформления
    Заказа.</p>
  <p>5.6. Mover выступает в качестве Агента Поставщика услуг исключительно для ограниченной цели оформления документов,
    которые подтверждают заключение Договора аренды транспортного средства с экипажем с Пользователем на Сервисе
    Mover.</p>
  <p>5.7. Mover будет осуществлять от имени Поставщика услуг канцелярскую работу, никоим образом, не ограничивая права
    Mover или расширяя обязательств в рамках настоящего Соглашения. Mover не является обслуживающей компанией и не
    управляет отдельными Поставщиками услуг или их деятельностью.</p>
  <p>5.8. Mover взимает плату с Пользователя от имени Грузоперевозчика. Mover принимает на себя обязательство
    перечислить поступившие средства, за вычетом комиссионных, на счет Грузоперевозчика.</p>
  <p>5.9. Платеж признается совершенным Пользователем в момент поступления денежных средств на счет Mover или в кассу
    Грузоотправителя. </p>
  <p>5.10. После подтверждения получения оплаты Mover направляет Пользователю сообщение с уведомлением о получении
    оплаты – Заказ считается оплаченным. </p>
  <p>5.11. Отправитель гарантирует, что имеет право использовать любые платежные средства, предусмотренные и
    используемые для оплаты аренды транспортного средства с экипажем. </p>
  <p>5.12. Отправитель обязуется немедленно уведомить Mover о любых изменениях в платежных реквизитах Грузоотправителя
    или банковской карты, используемого для оплаты.</p>
  <p>5.13. Mover не взимает с Пользователя комиссию за услуги и предоставляемые технологии, в том числе, помимо прочего
    за техническое обслуживание Платформы Mover.</p>
  <p>5.14. Mover вправе начислять Пользователю дополнительные сборы за эпизодически осуществляемые банковские операции,
    например, комиссию за банковский перевод с целью оплаты услуг или комиссию за возврат уплаченных денежных
    средств.</p>
  <p>5.15. Пользователь оплачивает счета, выставленные Mover, в пользу Грузоперевозчика в течение 3 (трх) банковских
    дней с момента подачи и согласования Заявки по каждой перевозке. Иные условия оплаты по Заявкам, дополнительным
    расходам, отражаются непосредственно в Заявках. Также Перевозчик вправе потребовать предварительную оплату, условия
    и размер которой определяются по согласованию сторон в Заявках.</p>
  <p>5.16 Пользователь имеет право оплатить Заказ бонусными рублями, полученными в результате реализации механизма
    Реферального Промокода, но не более чем 500 руб. за один Заказ. Сумма оплаты бонусными рублями части суммы оплаты
    Заказа не может превышать 30%.</p>

  <h4>6. Условия отказа от услуг.</h4>

  <p>6.1. В случае если Пользователь произвел оплату услуг и отказался от Заказа по собственной инициативе и уведомил об
    этом Администрацию не менее чем за 3 (Три) часа до оказания услуги, Администрация осуществляет возврат денежных
    средств Пользователю.</p>
  <p>6.2. Возвратный платеж осуществляется в размере стоимости оплаченных услуг, за вычетом фактически понесенных
    расходов (в соответствии со статьей 32 Федерального закона РФ «О защите прав потребителей в РФ» и части 1 статьи 782
    Гражданского кодекса РФ), на счет Пользователя в соответствии с выбранным способом оплаты в течение 20 (Двадцати)
    банковских дней с момента оформления возврата.</p>
  <p>6.3. В случае если Пользователь не воспользовался услугами, возврат денежных средств за неиспользованные услуги
    аренды транспортного средства с экипажем не производится.</p>

  <h4>7. Права и обязанности сторон</h4>

  <p>7.1. Пользователь имеет право:</p>
  <p>7.1.1. Получать информацию о правилах оплаты, имеющихся категориях транспортных средств и иной информации в
    отношении Грузоперевозчика, которой владеет Разработчик в рамках своих полномочий.</p>
  <p>7.1.2. Выбирать Грузоперевозчиков из предложенных в Сервисе и приложениях Mover. При этом Пользователь обязан
    самостоятельно ознакомиться с правилами аренды транспортного средства с экипажем.</p>
  <p>7.1.3. Пользователь вправе выбирать способ оплаты за услуги из предложенных в Приложении. При этом Пользователь
    обязуется самостоятельно ознакомиться с правилами пользования выбранным им способом платежа, либо согласовать способ
    платежа с администрацией Сервиса.</p>
  <p>7.2. Пользователь обязан: </p>
  <p>7.2.1. При оформлении Заказа предоставить необходимую верную, точную и полную информацию о Пользователе, грузе,
    дате, месте доставки и погрузки, сроке аренды транспортного средства с экипажем.</p>
  <p>7.2.2. Оплатить в полном объеме стоимость аренды транспортного средства с экипажем.</p>
  <p>7.2.3. Самостоятельно проверить данные, необходимые для формирования Заказа перед отправкой. Пользователь несет
    полную ответственность за достоверность данных, использованных им при оформлении Заказа.</p>
  <p>7.2.4. Произвести своевременную оплату цены договоров с Поставщиками услуг.</p>
  <p>7.2.5. Предоставить Mover информацию необходимую для исполнения договора. </p>
  <p>7.2.6. Предоставить Mover точную контактную информацию.</p>
  <p>7.2.7. Своевременно прибыть в место отгрузки/погрузки груза. Отсутствие по любым причинам приравнивается к отказу
    Пользователя от исполнения договора. </p>
  <p>7.2.8. Обеспечить   безопасность Личного Кабинета в течении всего срока действия настоящего Соглашения.
    Незамедлительно информировать Администрацию о смене или утери Логина и Пароля от аккаунта.</p>
  <p>7.2.9. Не вносить какие-либо изменения в программное обеспечение Сервиса и/или любую его часть своими силами или с
    привлечением третьих лиц, а также использовать какие-либо средства автоматизированного доступа, в том числе к
    клиентской части.</p>
  <p>7.3. Mover имеет право: </p>
  <p>7.3.1. Требовать соблюдения всех процедур оформления/оплаты Заказа по правилам, изложенным в настоящем
    Соглашении. </p>
  <p>7.3.2. Производить модификацию любого программного обеспечения, приостанавливать работу Приложения, при обнаружении
    существенных неисправностей, ошибок и сбоев, а также в целях проведения профилактических работ и предотвращения
    случаев несанкционированного доступа.</p>
  <p>7.3.3. Аннулировать оформленный Заказ. </p>
  <p>7.3.4. При несоблюдении Пользователем любого из пунктов Соглашения отказать ему в дальнейшем от предоставления
    Услуг.</p>
  <p>7.3.5. Привлекать третьих лиц для выполнения какой-либо части своих обязательств по настоящему Соглашению (включая,
    но не ограничиваясь, такие услуги как обработка и получение платежей, обеспечение работы сервера и проч.) и уступать
    или сублицензировать третьим лицам какую-либо часть своих прав по настоящему Соглашению.</p>
  <p>7.4. Mover обязан:</p>
  <p>7.4.1. Обеспечить круглосуточный доступ к Приложению и Сервисам Mover;</p>
  <p>7.4.2. Информировать о месте нахождения, контактных телефонах Грузоперевозчика;</p>
  <p>7.4.3. Информировать Пользователя о статусе Заказа и факте поступления оплаты, в случае, если Пользователь
    оплачивает услуги, используя Приложение. Показателем того, что Пользователь информирован, является факт отправки CMC
    сообщения на телефонный номер, указанный Пользователем при регистрации, содержащего подтверждающую информацию.</p>
  <p>7.4.4. Не использовать персональные данные Пользователя для несанкционированной рассылки материалов, не относящихся
    к оформленным Заказам.</p>
  <p>7.4.5. Осуществлять письменную техническую поддержку Пользователей, путем предоставления консультаций, по вопросам
    связанных с настройкой и работой Сервиса.</p>

  <h4>8. Заверения и гарантии</h4>

  <p>8.1. Грузоотправитель заявляет и гарантирует, что: </p>
  <p>8.1.1. не будет использовать Сервис в целях перевозки грузов, изъятых из гражданского оборота;</p>
  <p>8.1.2. не будут использовать Сервис, в целях перевозки скоропортящихся товаров или опасных материалов;</p>
  <p>8.1.3. является владельцем погружаемого товара, имеет необходимые права для обеспечения поставки таких товаров;</p>
  <p>8.1.4. использует Услугу для транспортировки грузов на свой собственный риск и соглашается, что Mover не несет
    ответственность за потерю каких-либо товаров.</p>
  <p>8.1.5. Грузоотправители несут полную ответственность за получение страховки, чтобы покрыть любые ожидаемые убытки,
    а также за указанные в заказе сроки/часы аренды транспортного средства с экипажем.</p>
  <p>8.2. Mover гарантирует своевременное предоставление информации о Пользователе Поставщикам услуг в сроки,
    согласованные с последними.</p>

  <h4>9. Ограничение ответственности</h4>

  <p>9.1. Mover сохраняет за собой право в любое время изменять оформление Сервиса, его контент, список сервисов,
    изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся
    на Сервисе, любые северные приложения в любое время с предварительным уведомлением или без такового.</p>
  <p>9.2. Mover не несет ответственности за информацию, предоставленную Поставщиком услуг.</p>
  <p>9.3. Информация, предоставляемая Поставщиком услуг, не подвергается обязательной проверке Mover перед ее
    публикацией (премодерации). Mover не дает никаких гарантий, явно выраженных или подразумевающихся, по отношению к
    подобной информации, ее точности, актуальности или достоверности.</p>
  <p>9.4. Сайт или его сервисы могут быть в то или иное время частично и/или полностью недоступны по причине проведения
    профилактических или иных работ по любым другим причинам технического характера. Администрация имеет право проводить
    необходимые профилактические или иные работы в то или иное время по личному усмотрению с предварительным
    уведомлением Пользователей или без такового.</p>
  <p>9.5. Администрация не несет ответственность за любые ошибки, прерывания, удаление, задержку при обработке или
    передаче данных, сбои или неправомерный доступ к аккаунту Пользователя.</p>
  <p>9.6. Администрация не несет ответственность за отказ Поставщика услуг в обслуживании или прекращение обслуживания
    Грузоотправителя, а также за действия третьих лиц, участвующих в расчетах при оплате Услуг, так как данные условия и
    правила находятся в исключительном ведении Грузоперевозчика.</p>
  <p>9.7. Администрация не несет ответственность за любые убытки и моральный вред, понесенные Пользователем в результате
    ошибочного понимания или непонимания им информации о порядке оформления/оплаты Заказа.</p>
  <p>9.8. Разработчик не несет ответственность в случае невозможности совершения Сделки вследствие неисправности
    программных и аппаратных средств, используемых Разработчиком и/или Грузоперевозчиком, а также каналов связи,
    предоставленных третьими лицами.</p>
  <p>9.9. Ни при каких обстоятельствах Администрация или ее представители не несут ответственность перед Пользователями
    или любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или
    потерянные данные, нарушение личных неимущественных прав, а именно причинение вреда чести, достоинству или деловой
    репутации, вызванные в связи с использованием  Сервисом, содержимого или иных материалов, к которым Пользователь или
    иные лица получили доступ с помощью  Сервиса, даже если Администрация предупреждала или указывала на возможность
    такого вреда.</p>

  <h4>10. Форс-мажор</h4>

  <p>10.1. Стороны по настоящему Соглашению освобождаются от ответственности за полное или частичное неисполнение своих
    обязательств в случае, если такое неисполнение явилось следствием обстоятельств непреодолимой силы, то есть событий,
    которые нельзя было предвидеть или предотвратить.</p>
  <p>10.2. При наступлении обстоятельств, указанных в пункте 10.1. настоящего Соглашения, каждая Сторона должна не
    позднее 7 (Семи) дней с момента наступления таких обстоятельств известить о них в письменном виде другую Сторону.
    Извещение должно содержать данные о характере обстоятельств, оценку их влияния на возможность исполнения Стороной
    своих обязательств по данному Соглашению, а также предполагаемые сроки их действия.</p>
  <p>10.3. В случае наступления обстоятельств, предусмотренных пунктом 10.1. настоящего Соглашения, срок выполнения
    Стороной обязательств по настоящему Соглашению отодвигается соразмерно времени, в течение которого действуют эти
    обстоятельства и их последствия. Если действие обстоятельств непреодолимой силы продолжается свыше одного месяца,
    Стороны проводят дополнительные переговоры для выявления приемлемых альтернативных способов исполнения настоящего
    Соглашения.</p>

  <h4>11. Авторское право</h4>

  <p>11.1. Mover и ее лицензиары сохраняют за собой все интеллектуальные права в отношении Сервиса.</p>
  <p>11.2. Под «Интеллектуальными правами» подразумеваются любые права, связанные с обладанием авторскими правами,
    контентом, шаблонами, товарными знаками, знаками обслуживания, торговыми наименованиями, коммерческими тайнами,
    патентами и иными правами, признаваемые в любой юрисдикции, вне зависимости от того, имеют ли данные права полную
    юридическую силу.</p>
  <p>11.3. Пользователь имеет право использовать Сайт исключительно в целях выполнения своих обязательств по настоящему
    Соглашению и на условиях, изложенных в Лицензионном соглашении.</p>
  <p>11.4. Пользователь не имеет право сублицензировать, продавать, передавать, или иным образом использовать в
    коммерческих целях Сервис.</p>
  <p>11.5. Пользователь не имеет право модифицировать, декомпилировать какую-либо часть Сервиса;</p>
  <p>11.6. Администрация Mover оставляет за собой право отзывать, расширять и иным образом изменять Сервис в любое время
    и по собственному усмотрению.</p>
  <p>11.7. Пользователь не имеет права частично или полностью изменять, или размещать любые материалы, содержащиеся на
    Сервисе или опубликованные на ресурсах третьих лиц, на другом сервере или интернет-устройствах, за исключением
    случаев, явно разрешенных Mover.</p>

  <h4>12. Срок действия и порядок расторжения</h4>

  <p>12.1. Данное соглашение действует с момента регистрации на Сайте до 31 (Тридцать первого) декабря текущего
    календарного года. Действие настоящего Соглашения автоматически продлевается на каждый следующий календарный год в
    случае отсутствия возражений, связанных с деятельностью Сервиса и его сервисами одной из сторон.</p>
  <p>12.2. Любая сторона может расторгнуть настоящее Соглашение в любой момент, вне зависимости от наличия или
    отсутствия соответствующих оснований.</p>
  <p>12.3. В случае прекращения действия Соглашения, Вознаграждение Исполнителю будет выплачено в течении 10 (Десяти)
    дней после прекращения действия Соглашения.</p>

  <h4>13. Прочее</h4>

  <p>13.1. Настоящим Пользователь дает согласие на использование:</p>
  <ul>
    <li>a. электронных средств для заключения данного Соглашения и предоставления любых уведомлений по-настоящему
      Соглашения; а также
    </li>
    <li>b. электронных записей для хранения информации, связанной с настоящим Соглашением, или использованием Сервиса.
    </li>
  </ul>
  <p>Уведомления по настоящему Соглашению являются действительными только в случае, если они оформлены в письменном виде
    и направлены:</p>
  <ul>
    <li>c. Администрацией по электронной почте (по предоставленному Вами адресу);</li>
    <li>d. посредством размещения на Сайте;</li>
  </ul>
  <p>Или Исполнителем:</p>
  <ul>
    <li>e. по электронной почте по адресу info@mover24.com или другому адресу, указанному Администрацией в письменном
      виде. Датой получения уведомления считается дата передачи данного уведомления.
    </li>
  </ul>
  <p>13.2. Пользователь, используя Приложение, даёт согласие на сбор данных о своём местоположении в фоновом режиме, для
    активации функций по отображению доступных исполнителей и заказов поблизости, отображению своего маршрута следования
    для исполнителей и его отслеживанию для заказчиков, функционал работает, даже если приложение закрыто или не
    используется.</p>
  <p>13.3. Пользователь, используя Приложение, соглашается с применением Политики конфиденциальности.</p>
  <p>13.4. Настоящее Соглашение представляет неделимое соглашение между компанией ООО “8РХ” и Пользователем в отношении
    предмета настоящего Соглашения</p>
  <p>13.5. Настоящее Соглашение, а также любые разногласия, споры или претензии, возникающие вследствие или в связи с
    ним («Претензии»), регулируются и толкуются в соответствии с законодательством Российской Федерации, без учета норм
    коллизионного права и Конвенции ООН о договорах международной купли-продажи товаров (CISG).</p>
  <p>13.6. Если какая-либо часть настоящего Соглашения будет признана недействительной или не имеющей законной силы,
    данная часть будет толковаться с учетом исходных намерений сторон, а остальные части будут оставаться
    действительными.</p>

  <h4>Адреса и Банковские реквизиты сторон</h4>

  <p>Общество с ограниченной ответственностью "8РХ"</p>
  <p>Юридический адрес: 127137, Москва город, улица Правды, дом 24, строение 2, эт 1 пом 1 ком 144</p>
  <p>Фактический адрес: 127137, Москва город, улица Правды, дом 24, строение 2, эт 1 пом 1 ком 144</p>
  <p>Адрес для корреспонденции: 171161, Тверская область г. Вышний Волочек ул. 3я Пролетарская д.105</p>
  <p>ИНН/КПП 7722451767/772201001</p>
  <p>ОГРН: 1187746412776</p>
  <p>Номер счета: 40702810601600011068
    в АО «АЛЬФА-БАНК»
  </p>
  <p>БИК: 044525593</p>
  <p>К/с: 30101810200000000593 в ГУ БАНКА РОССИИ ПО ЦФО</p>
  <p>info@mover24.ru</p>
  <p>Генеральный директор – Мегерян Амаяк Людвигович</p>
</div>
