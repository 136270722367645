import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "formatPhone"})
export class FormatPhonePipe implements PipeTransform {
  private phoneRegex = /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/;

  transform(value?: string): string {
    let result = this.phoneRegex.exec(value || '');
    return result && `+${result[1]} (${result[2]}) ${result[3]}-${result[4]}-${result[5]}` || value || '';
  }
}
