import {Injectable} from "@angular/core";
import {SessionStorageService} from "./session-storage.service";

@Injectable()
export class TokenService {
  private _token: string | undefined;

  constructor(private sessionStorageService: SessionStorageService) {
    this._token = this.sessionStorageService.getItem('token') || undefined;
  }

  get token(): string | undefined {
    return this._token;
  }

  set token(value: string | undefined) {
    this._token = value;

    if(value === undefined)
      this.sessionStorageService.removeItem('token');
    else
      this.sessionStorageService.setItem('token', value);
  }
}
