import {Account} from "./account";
import {Destination} from "./destination";
import {Image} from "./image";
import {DeliverySchema} from "./delivery-schema";
import {Point} from "./point";
import {TaxiSearch} from "./taxi-search";
import {ExtraSearchParams} from "./extra-search-params";
import {OrderFeature} from "./order-feature";
import {CompanyClient} from "./company-client";
import {LegalEntity} from "./legal-entity";
import {City} from "./city";
import {OrderArea} from "./order-area";
import {TransportTariff} from "./transport-tariff";
import {LoaderTariff} from "./loader-tariff";
import {AssemblerTariff} from "./assembler-tariff";
import {LiftingTariff} from "./lifting-tariff";
import {CargoInfo} from "./cargo-info";

export class OrderDraft {
  id?: number;
  features?: OrderFeature[];
  comment?: string;
  notes?: string;
  intercom_dialog?: string;
  client?: Account;
  cost?: number;
  fixed_discount?: number;
  percent_discount?: number;
  total_cost?: number;
  avoid_ttk?: boolean;
  destinations?: Destination[];
  loaders?: number;
  photos?: Image[];
  assembly?: boolean;
  minutes?: number;
  distance?: number;
  out_mkad_distance?: number;
  points?: Point[];
  delivery?: boolean;
  delivery_status?: string;
  delivery_schema?: DeliverySchema;
  delivery_company_client?: CompanyClient;
  delivery_time_slot_begin?: string;
  delivery_time_slot_end?: string;
  pay_method?: string;
  pay_method_option?: string;
  delivery_storehouse_arrival?: string;
  created_at?: string;
  taxi_search?: TaxiSearch;
  extra_search_params?: ExtraSearchParams;
  calculation?: any;
  auto_accept_taxi?: boolean;
  legal_entity?: LegalEntity;
  taxi_schema?: string;
  auto_assigment_crew?: boolean;
  auto_external_search?: boolean;
  manual_search?: boolean;
  cargo_storage?: boolean;
  freighter_external_id?: string;
  external_freighter?: string;
  city?: City;
  areas?: OrderArea[];
  tariffs?: TransportTariff[];
  loader_tariffs?: LoaderTariff[];
  assembler_tariffs?: AssemblerTariff[];
  lifting_tariffs?: LiftingTariff[];
  is_freighters_draft?: boolean;
  cargo_info?: CargoInfo;
  is_default?: boolean;
  custom_minutes? : number;

  static clone(draft: OrderDraft): OrderDraft {
    return JSON.parse(JSON.stringify(draft)) as OrderDraft;
  }
}
