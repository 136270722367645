import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GeoService} from "../_services/geo.service";
import {Subject} from "rxjs";
import {Address} from "../_models/address";
import {debounceTime, distinctUntilChanged, finalize, switchMap} from "rxjs/operators";
import {DestinationPoint} from "../_models/destination-point";
import { EventEmitter } from '@angular/core';
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {AutocompleteComponent} from "angular-ng-autocomplete";

@Component({
  selector: 'address-field',
  templateUrl: './address-field.component.html',
  styleUrls: ['./address-field.component.scss']
})
export class AddressFieldComponent implements OnInit, OnChanges {
  @Input() address?: DestinationPoint;
  @Input() autoFocus = false;
  @Input() placeholder = 'Введите адрес';
  @Input() class = 'input_adress_preview';
  @Input() name = 'address';
  @Input() disabled = false;

  @Output() addressChange = new EventEmitter<DestinationPoint>();
  @Output() cleared = new EventEmitter<void>();

  @ViewChild(AutocompleteComponent) addressField?: AutocompleteComponent;

  private geoStream = new Subject<string>();

  value: string|Address = '';
  keyword = 'formated_address';
  data: Address[] = [];
  isLoading = false;

  constructor(private geoService: GeoService) { }

  ngOnInit(): void {
    this.syncAddress();
    this.initGeoStream();

    if(this.autoFocus) {
      setTimeout(() => {
        if(this.addressField)
          this.addressField.focus();
      }, 500);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.syncAddress();
  }

  private syncAddress(): void {
    if(this.address) {
      let address = new Address();
      address.formated_address = this.address.addr;
      address.longitude = this.address.lon;
      address.latitude = this.address.lat;

      this.value = address;
    } else {
      this.value = '';
    }

    // this.value = this.address?.addr || '';
  }

  private initGeoStream(): void {
    this.geoStream = new Subject<string>();
    this.geoStream
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(search => {
          this.isLoading = true;
          return this.geoService.getAddresses(search).pipe(finalize(() => this.isLoading = false));
        })
      )
      .subscribe(
        addresses => {
          this.data = addresses;
          this.addressField?.focus();
        },
        e => {
          if(e instanceof HttpErrorResponse && e.status == 404) {
            this.data = [];
          } else {
            console.error(e);
          }
          this.initGeoStream();
        }
      );
  }

  private loadLocationForAddresses(address: Address): void {
    this.geoService
      .getGooglePlace(address.google_place_id!)
      .subscribe(a => this.applyAddresses(a));
  }

  private applyAddresses(address: Address): void {
    this.address = this.address || new DestinationPoint();
    this.address.addr = address.formated_address!;
    this.address.lon = address.longitude!;
    this.address.lat = address.latitude!;

    this.addressChange.emit(this.address);
  }

  selectEvent(item: Address) {
    if(item.latitude && item.longitude) {
      this.applyAddresses(item);
    } else if(item.google_place_id) {
      this.loadLocationForAddresses(item);
    }
  }

  customFilter(items: Address[], query: string): Address[] {
    return items;
  }

  onChangeSearch(val: string|Address) {
    if(typeof val != 'string')
      return;

    if(val == '') {
      this.address = new DestinationPoint();
      this.address.addr = '';
      this.address.lat = 0;
      this.address.lon = 0;
      this.addressChange.emit(this.address);
    } else {
      this.geoStream.next(val);
    }
  }

  onFocused(){
    // do something when input is focused
  }

  onCleared() {
    this.cleared.emit();
  }
}
