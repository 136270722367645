export class DestinationPoint {
  lat!: number;
  lon!: number;
  addr!: string;

  static getCloneOf(point: DestinationPoint): DestinationPoint {
    let pointClone = new DestinationPoint();
    pointClone.lat = point.lat;
    pointClone.lon = point.lon;
    pointClone.addr = point.addr;

    return pointClone;
  }
}
