export class TierDescriptor {
  cost: number = 0;
  costForHour: number = 0;
  costForTwoHours: number = 0;
  orderDuration: number = 0;
  calculation: any;

  constructor(
    public name: string,
    public identifier: string,
    public tonnage: string,
    public volume: string,
    public volumeSubscription: string,
    public lengthSubscription: string,
    public description: string,
    public bodies: string[],
    public image: string,
    public iconClass: string,
    public transportLength: string,
    public transportWidth: string,
    public transportHeight: string,
    public transportVolume: string,
    public transportTonnage: string,
  ) {}

  cloneBase(): TierDescriptor {
    return new TierDescriptor(
      this.name,
      this.identifier,
      this.tonnage,
      this.volume,
      this.volumeSubscription,
      this.lengthSubscription,
      this.description,
      this.bodies,
      this.image,
      this.iconClass,
      this.transportLength,
      this.transportWidth,
      this.transportHeight,
      this.transportVolume,
      this.transportTonnage
    );
  }
}
